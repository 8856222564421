import React from "react";

import Navegacao from "../components/navbar";

import { Container } from "react-bootstrap";

function Erro() {

  
    return (

        <>

            
            <Container>
                <br />
                <h1>404 - Página Não Encontrada!</h1>

            </Container>

        </>



    )
}

export default Erro;
