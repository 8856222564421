import React, { useState, useContext, useEffect } from "react"
import {
    makeStyles,
    Box,
    Toolbar,
    Grid,
    Typography,
    Dialog,
    DialogContentText,
    DialogTitle,
    CircularProgress,
    DialogContent,
    AccordionSummary,
    AccordionDetails,
    Accordion,
    ListItem,
    ListItemText
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTheme } from '@material-ui/core/styles';
import { GlobalContext } from "../contexts/global";
import { msgAviso, msgConfirmacao } from "../components/sweet";
import { auth, db } from '../firebase'
import { TiDelete } from 'react-icons/ti'

import Rel_Relacao_Geral_de_Vencimentos from "./reports/produtos/relacao_geral_vencimentos";


import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Sidebar from "../components/sidebar";
import BarraSuperior from "../components/barra";

import DataTable from 'react-data-table-component';



const aviso = withReactContent(Swal)

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        backgroundColor: '#F4F6F8',
        flexGrow: 1,
        spacing: 4,
    },
    listItemText: {
        // fontSize: 18,
        padding: 0,
        transition: '0.3s',
        '&:hover': {
            fontWeight: 'bold'
        }
    },
    linha: {
        width: '100%',
    },
    listItem: {
        paddingTop: 4,
        paddingBottom: 4,
        height: 50,
        transition: '0.3s',
        '&:hover': {
            paddingLeft: 25,
        }
    },
    margin: {
        margin: theme.spacing(1),
    },
    heading: {
        fontSize: 18,
        fontWeight: 'bold'
    },
    formulario: {
        display: 'flex',
        flexWrap: 'wrap',
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: '#E58934',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#E58934',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#E58934',
            },
            '&:hover fieldset': {
                borderColor: '#E58934',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#E58934',
            },
        },
    },
}));


function Relatorios() {

    useEffect(() => {

        console.log('Chegou no useEffect do Relatórios')
        const conectado = auth.onAuthStateChanged(user => {
            if (user) {
             
                    salvarLogin(user.email)
                    ValidaUsuario(user.email)
                    // registraLogin(user.email, Device.deviceName)
                    console.log(user.email)

                    // if (plano.plano == 'GRATUITO') {
                    //     console.log('Sai Daqui')
                    //     msgAviso('Plano Gratuíto', 'Seu Plano Não Tem Acesso aos Relatórios', 'info', 'OK')
                    //     navigate("/meu-plano")
                    // }

                    // CarregaColetas(user.email)
              
            } else {
                console.log('Sai Daqui')
                navigate("/?origem=relatorios")
            }
        })

        return conectado;

    }, [])

    const Navigate = useNavigate()

    const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [msgLoading, setMsgLoading] = useState('')

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    const { conexao, usuario, qtdeTimes, registrarCodigoTime, registrarTimeAtual, timeAtual, codigoTime, salvarLogin, plano, salvarDadosPlano, registrarQtdeTimes } = useContext(GlobalContext)


    const rel_produtos = [
        {
            "identificacao": "rel_prod_1",
            "nome": "Relação Geral de Vencimentos",
        }
    ];

    const rel_coletas = [
        {
            "identificacao": "rel_cole_1",
            "nome": "Coletas por Usuário",
        },
        {
            "identificacao": "rel_cole_2",
            "nome": "Coletas por Departamento",
        },

    ];

    const rel_recebimento = [
        {
            "identificacao": "rel_rece_1",
            "nome": "Tempo Médio Entre Recebimento e Vencimento",
        }
    ];



    function gerarRelatorio(opcao) {
        if (opcao == 'rel_prod_1') {
            // const qtdeLinhas = prompt("Quantas Linhas?")
            // alert(qtdeLinhas)

            setLoading(true)
            setMsgLoading('Obtendo Dados do Relatório')

            const urlRelatorio = conexao.endereco + "/ListaColetas"

            let body = {
                "usuario": usuario.email,
                "data": null,
                "dpto": null,
                "time": null
            }

            let request = new XMLHttpRequest()
            request.timeout = 10000
            request.open("POST", urlRelatorio, true)
            request.setRequestHeader("Content-type", "application/json")
            request.setRequestHeader("Authorization", conexao.autenticacao)
            request.send(JSON.stringify(body))

            request.ontimeout = function () {
                setLoading(false)
                alert('Erro ao Obter Coletas', 'TimeOut')
            }

            request.onloadend = async function () {

                let resposta = JSON.stringify(this.status)
                let respostaJson = await JSON.parse(this.response)

                console.log('Dados para o Relatório: ' + respostaJson)

                setLoading(false)
                Rel_Relacao_Geral_de_Vencimentos(respostaJson)

            }

        }
    }

    function ValidaUsuario(usuarioInformado) {

        console.log('Validando Licença do Usuário')

        const url = conexao.endereco + "/ValidaUsuario?usuario=" + usuarioInformado

        console.log('Endereço Configurado: ' + url)

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("GET", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("Authorization", conexao.autenticacao)
        requisicao.send()

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
        }

        requisicao.onerror = function () {
            salvarDadosPlano('GRATUITO', '', '')
            console.log('Deu Erro: ' + this.responseText)
        }

        requisicao.onloadend = function () {
            console.log('Deu Certo!')
            console.log('RESPONSE: ' + this.response)
            let resposta = JSON.parse(this.response)
            console.log('StatusCode: ' + requisicao.status)
            if (requisicao.status === 200) {

                salvarDadosPlano(resposta.plano, resposta.vencimento, resposta.status)

                // if (resposta.plano == 'GRATUITO') {
                //     navigate("/meu-plano")
                // }
                registrarQtdeTimes(resposta.times)


            } else {
                salvarDadosPlano('GRATUITO', '', '')
            }
            console.log(resposta);
        }

    }





    return (
        <div className={classes.root}>
            <BarraSuperior />
            <Box display='flex'>
                <Sidebar />
                <div className={classes.root}>
                    <Box p={8}>
                        <Toolbar />
                        <Grid container spacing={3}>
                            <Grid item xs={12} >
                                <Typography
                                    component={'span'}
                                    variant='h5'
                                    color='textPrimary'
                                    style={{ fontWeight: 600 }}
                                >
                                    Relatórios
                                </Typography>
                                <hr />
                            </Grid>
                        </Grid>

                        <br />

                        <Grid container spacing={3}>
                            <Grid item xs={12}>

                                <Accordion expanded={expanded === 'rel-produtos'} onChange={handleChange('rel-produtos')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className={classes.heading}>Produtos</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>

                                        <div className={classes.linha}>
                                            {rel_produtos.map((rel) => (
                                                <ListItem button className={classes.listItem} onClick={() => {
                                                    gerarRelatorio(rel.identificacao)
                                                }} key={rel.identificacao}>
                                                    <ListItemText
                                                        classes={{
                                                            primary: classes.listItemText,
                                                        }}
                                                        primary={rel.nome}
                                                    />
                                                </ListItem>
                                            ))}
                                        </div>



                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'rel-coletas'} onChange={handleChange('rel-coletas')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className={classes.heading}>Coletas</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>

                                        <div className={classes.linha}>
                                            {rel_coletas.map((rel) => (
                                                <ListItem button className={classes.listItem} onClick={() => {
                                                    gerarRelatorio(rel.nome)
                                                }} key={rel.identificacao}>
                                                    <ListItemText
                                                        classes={{
                                                            primary: classes.listItemText,
                                                        }}
                                                        primary={rel.nome}
                                                    />
                                                </ListItem>
                                            ))}
                                        </div>



                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'rel-2'} onChange={handleChange('rel-2')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className={classes.heading}>Recebimento de Mercadorias</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>

                                        <div className={classes.linha}>
                                            {rel_recebimento.map((rel) => (
                                                <ListItem button className={classes.listItem} onClick={() => {
                                                    gerarRelatorio(rel.nome)
                                                }} key={rel.identificacao}>
                                                    <ListItemText
                                                        classes={{
                                                            primary: classes.listItemText,
                                                        }}
                                                        primary={rel.nome}
                                                    />
                                                </ListItem>
                                            ))}
                                        </div>

                                    </AccordionDetails>
                                </Accordion>


                            </Grid>
                        </Grid>

                    </Box>




                </div>


                <Dialog
                    open={loading}
                    keepMounted
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title" style={{ fontSize: 16, textAlign: 'center' }} disableTypography={true}>{msgLoading}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">

                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <CircularProgress size={24} color={'primary'} />
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>






            </Box >

        </div >
    )
}

export default Relatorios