import React from "react";
import { useNavigate, } from 'react-router-dom';
import { BiBarcodeReader } from 'react-icons/bi'

import { auth } from '../firebase'

import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap'


function Navegacao() {

    const Navigate = useNavigate()



    function Sair() {
        auth
            .signOut()
            .then(() => {
                Navigate("/")
                console.log('Deslogando da Conta')
            })
            .catch(error => alert(error.message))
    }


    return (
        <>
            <Navbar className="barra-dash" expand="lg">
                <Container>
                    <Navbar.Brand className="titulo-dash" onClick={() => { Navigate("/dashboard") }}><p>< BiBarcodeReader size={24} color='#ffffff' className="icone-nav" />Gestão de Validades</p></Navbar.Brand>

                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link onClick={() => { Navigate("/dashboard") }}>Dashboard</Nav.Link>
                            <NavDropdown title="Produtos" id="basic-nav-dropdown">
                                <NavDropdown.Item onClick={() => { Navigate("/produtos") }}>Produtos</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => { Navigate("/grupos-e-departamentos") }}>Grupos e Departamentos</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={() => { Navigate("/integracao-erp") }}>Integração ERP</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Relatórios" id="basic-nav-dropdown">
                                <NavDropdown.Item onClick={() => { Navigate("/rel-vencimentos") }}>Vencimentos</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Configurações" id="basic-nav-dropdown">
                                <NavDropdown.Item onClick={() => { Navigate("/time") }}>Time</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link onClick={() => { Sair() }}>Sair</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>

            </Navbar>

        </>

    )
}

export default Navegacao;