import React, { useState, useContext, useEffect } from "react"
import {
    makeStyles,
    Box,
    Toolbar,
    Grid,
    Typography,
    Button,
    Dialog,
    DialogContentText,
    DialogTitle,
    DialogActions,
    TextField,
    TextareaAutosize,
    Checkbox,
    FormControlLabel,
    CircularProgress,
    DialogContent,
    InputLabel,
    Input,
    FormControl,
    Tooltip
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTheme } from '@material-ui/core/styles';
import { GlobalContext } from "../contexts/global";
import { msgAviso, msgConfirmacao } from "../components/sweet";
import { auth, db } from '../firebase'
import { useNavigate } from 'react-router-dom';
import { TiDelete } from 'react-icons/ti'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Sidebar from "../components/sidebar";
import BarraSuperior from "../components/barra";

import DataTable from 'react-data-table-component';



const aviso = withReactContent(Swal)

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        backgroundColor: '#F4F6F8',
        flexGrow: 1,
        spacing: 4,
    },

    margin: {
        margin: theme.spacing(1),
    },

    formulario: {
        display: 'flex',
        flexWrap: 'wrap',
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: '#E58934',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#E58934',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#E58934',
            },
            '&:hover fieldset': {
                borderColor: '#E58934',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#E58934',
            },
        },
    },
}));

function Integracoes() {

    useEffect(() => {

        console.log('Chegou no useEffect do Destinatarios')
        const conectado = auth.onAuthStateChanged(user => {
            if (user) {

                salvarLogin(user.email)
                console.log(user.email)
                ValidaUsuario(user.email)
                // CarregaColetas(user.email)
                listarIntegracoes(user.email)

            } else {
                console.log('Sai Daqui')
                navigate("/?origem=integracoes")
            }
        })

        return conectado;

    }, [])

    async function ExcluirIntegracao(idInformado) {

        aviso.fire({
            title: 'Atenção',
            text: 'Confirma Exclusão da Integração?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#E58934',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {

                setLoading(true)
                setMsgLoading('Excluindo Integração')

                const urlDelInt = conexao.endereco + "/ExcluirIntegracao?idIntegracao=" + idInformado

                console.log(urlDelInt)

                let request = new XMLHttpRequest()
                request.timeout = 10000
                request.open("GET", urlDelInt, true)
                request.setRequestHeader("Content-type", "application/json")
                request.setRequestHeader("Authorization", conexao.autenticacao)
                request.send()

                request.ontimeout = function () {
                    setLoading(false)
                    alert('Erro no Envio da Requisição', 'TimeOut')
                }

                request.onload = function () {

                    let resposta = JSON.stringify(this.status)
                    let respostaJson = JSON.parse(this.response)

                    setDescricao('')
                    setErp('')
                    setBancoTipo('')
                    setBancoServidor('')
                    setBancoBase('')
                    setBancoPorta('')
                    setBancoUsuario('')
                    setBancoSenha('')

                    setLoading(false)
                    setOpenCadastroDest(false)

                    console.log('Resposta do Envio: ' + JSON.stringify(respostaJson.status))

                    msgAviso(respostaJson.status, '', 'success', 'OK')

                    listarIntegracoes(usuario.email)

                }

            }
        })


    }

    const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigate()

    const [listaIntegracoes, setListaIntegracoes] = useState([])

    const [openCadastroDest, setOpenCadastroDest] = useState(false)

    const [loading, setLoading] = useState(false)
    const [msgLoading, setMsgLoading] = useState('')


    const [descricao, setDescricao] = useState('')
    const [erp, setErp] = useState('')
    const [bancoTipo, setBancoTipo] = useState('')
    const [bancoServidor, setBancoServidor] = useState('')
    const [bancoBase, setBancoBase] = useState('')
    const [bancoPorta, setBancoPorta] = useState('')
    const [bancoUsuario, setBancoUsuario] = useState('')
    const [bancoSenha, setBancoSenha] = useState('')

    const { conexao, usuario, qtdeTimes, registrarCodigoTime, registrarTimeAtual, timeAtual, codigoTime, salvarLogin, salvarDadosPlano, registrarQtdeTimes, plano } = useContext(GlobalContext)


    const colunasIntegracoes = [
        {
            name: 'Descrição',
            selector: row => row.descricao,
            sortable: true,
        },
        {
            name: 'ERP',
            selector: row => row.erp,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            wrap: true
        },
        {
            name: 'Chave',
            selector: row => row.chave,
            sortable: true,
            wrap: true
        },
        {
            name: '',
            button: true,
            cell: row => (
                < a onClick={() => {
                    ExcluirIntegracao(row.id)
                }} >
                    <TiDelete size="24" color="#d9534f" title="Excluir Integração" />
                </a>
            ),
        },
    ];


    const listaERPsIntegrados = [
        {
            nome: 'RP Info'
        },
        {
            nome: 'Hipcom'
        },
        {
            nome: 'VR Software'
        },

    ];

    const listaTiposBanco = [
        {
            nome: 'PostgreSQL'
        },
        {
            nome: 'MySQL'
        },
        {
            nome: 'SQLServer'
        },

    ];

    async function AdicionaIntegracao() {

        setLoading(true)
        setMsgLoading('Cadastrando integração')

        const urlAddAcao = conexao.endereco + "/AdicionaIntegracao"

        let body = {
            "usuario": usuario.email,
            "descricao": descricao,
            "erp": erp,
            "banco_tipo": bancoTipo,
            "banco_servidor": bancoServidor,
            "banco_base": bancoBase,
            "banco_porta": bancoPorta,
            "banco_usuario": bancoUsuario,
            "banco_senha": bancoSenha,
        }

        console.log(body)

        let request = new XMLHttpRequest()
        request.timeout = 10000
        request.open("POST", urlAddAcao, true)
        request.setRequestHeader("Content-type", "application/json")
        request.setRequestHeader("Authorization", conexao.autenticacao)
        request.send(JSON.stringify(body))

        request.ontimeout = function () {
            setLoading(false)
            alert('Erro no Envio do Cadastro', 'TimeOut')
        }

        request.onload = function () {

            let resposta = JSON.stringify(this.status)
            let respostaJson = JSON.parse(this.response)

            setDescricao('')
            setErp('')
            setBancoTipo('')
            setBancoServidor('')
            setBancoBase('')
            setBancoPorta('')
            setBancoUsuario('')
            setBancoSenha('')

            setLoading(false)
            setOpenCadastroDest(false)

            console.log('Resposta do Envio: ' + JSON.stringify(respostaJson.status))

            msgAviso(respostaJson.status, '', 'success', 'OK')

            listarIntegracoes(usuario.email)

        }


    }

    function ValidaUsuario(usuarioInformado) {

        console.log('Validando Licença do Usuário')

        const url = conexao.endereco + "/ValidaUsuario?usuario=" + usuarioInformado

        console.log('Endereço Configurado: ' + url)

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("GET", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("Authorization", conexao.autenticacao)
        requisicao.send()

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
        }

        requisicao.onerror = function () {
            salvarDadosPlano('GRATUITO', '', '')
            console.log('Deu Erro: ' + this.responseText)
        }

        requisicao.onloadend = function () {
            console.log('Deu Certo!')
            console.log('RESPONSE: ' + this.response)
            let resposta = JSON.parse(this.response)
            console.log('StatusCode: ' + requisicao.status)
            if (requisicao.status === 200) {

                salvarDadosPlano(resposta.plano, resposta.vencimento, resposta.status)

                if (resposta.plano !== 'COMPLETO') {
                    console.log('Sai Daqui')
                    msgAviso('Plano ' + resposta.plano, 'Apenas o Plano Completo tem Acesso a Integrações', 'info', 'OK')
                    navigate("/meu-plano")
                }

                registrarQtdeTimes(resposta.times)


            } else {
                salvarDadosPlano('GRATUITO', '', '')
            }
            console.log(resposta);
        }

    }



    function listarIntegracoes(emailInformado) {

        setLoading(true)
        setMsgLoading('Obtendo Integrações')

        let url = conexao.endereco + '/ListaIntegracoes?usuario=' + emailInformado

        console.log('Request Enviado: ' + url)

        let request = new XMLHttpRequest()
        request.timeout = 10000
        request.open("GET", url, true)
        request.setRequestHeader("Content-Type", "application/json")
        request.setRequestHeader("Authorization", conexao.autenticacao)
        request.send()

        request.ontimeout = function () {
            msgAviso('Atenção', 'TimeOut na Conexão', 'warning', 'OK')
            setLoading(false)
        }

        request.onload = function () {

            setLoading(false)

            let codigoResposta = JSON.stringify(this.status)
            let textoResposta = JSON.parse(this.response)

            console.log('Resposta Antes do StatusCode: ' + textoResposta)
            console.log(codigoResposta)

            if (codigoResposta != 200) {

                msgAviso('Atenção', 'Acesso Negado', 'error', 'ok');
                console.log(textoResposta)

            } else {

                setListaIntegracoes(textoResposta)
                console.log('Busca Finalizada com Sucesso!')

            }
        }
    }



    return (
        <div className={classes.root}>
            <BarraSuperior />
            <Box display='flex'>
                <Sidebar />
                <div className={classes.root}>
                    <Box p={8}>
                        <Toolbar />
                        <Grid container spacing={3}>
                            <Grid item xs={3} >
                                <Typography
                                    component={'span'}
                                    variant='h5'
                                    color='textPrimary'
                                    style={{ fontWeight: 600 }}
                                >
                                    Integrações
                                </Typography>

                                <Typography
                                    variant='h6'
                                    color='textPrimary'
                                    style={{ fontWeight: 400 }}
                                >
                                    Integração com ERP
                                </Typography>
                            </Grid>
                            <Grid item xs={9} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                {JSON.stringify(listaIntegracoes) === '[]' &&
                                    <Button
                                        variant="contained"
                                        // color="primary"
                                        style={{ color: 'white', backgroundColor: '#E58934' }}
                                        onClick={() => {
                                            setOpenCadastroDest(true)
                                        }}>
                                        configurar integração
                                    </Button>
                                }
                            </Grid>

                        </Grid>

                        <br />

                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <DataTable
                                    columns={colunasIntegracoes}
                                    data={listaIntegracoes}
                                    // onRowClicked={(row, index) => { msgAviso('Mensagem', row.mensagem, 'info', 'OK') }}
                                    // expandOnRowClicked={true}
                                    striped={true}
                                    highlightOnHover={true}
                                    responsive={true}
                                    pointerOnHover={true}
                                    noDataComponent={"Nenhum Registro Encontrado =/"}
                                />

                            </Grid>
                        </Grid>

                    </Box>


                    <Dialog
                        open={openCadastroDest}
                        keepMounted
                        fullWidth
                        maxWidth={'md'}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                        onClose={() => {
                            setOpenCadastroDest(false)
                        }}
                    >
                        <DialogTitle id="alert-dialog-slide-title" style={{ fontSize: 18, textAlign: 'center', color: '#E58934' }} disableTypography={true}>{'Integração com ERP'}</DialogTitle>

                        <DialogContentText id="alert-dialog-slide-description" >

                            <form className={classes.formulario} noValidate autoComplete="off">
                                <Grid sm={12} xs={12} md={12} lg={12} container spacing={1} style={{ height: 320, padding: 30 }}>

                                    <FormControl className={classes.margin} fullWidth style={{ marginRight: 90 }}>
                                        <InputLabel>Descrição</InputLabel>
                                        <Input
                                            value={descricao}
                                            onChange={(event) => { setDescricao(event.target.value) }}
                                        />
                                    </FormControl>

                                    <Autocomplete
                                        options={listaERPsIntegrados}
                                        getOptionLabel={(option) => option.nome}
                                        style={{ width: 300 }}
                                        inputValue={erp}
                                        onInputChange={(event, newInputValue) => {
                                            setErp(newInputValue);
                                        }}
                                        renderInput={(params) => <TextField {...params}
                                            label="ERP"
                                        />}
                                    />
                                    <Autocomplete
                                        options={listaTiposBanco}
                                        getOptionLabel={(option) => option.nome}
                                        style={{ width: 300 }}
                                        inputValue={bancoTipo}
                                        onInputChange={(event, newInputValue) => {
                                            setBancoTipo(newInputValue);
                                        }}
                                        renderInput={(params) => <TextField {...params}
                                            label="Banco de Dados"
                                        />}
                                    />

                                    <FormControl className={classes.margin} style={{ width: 120 }}>
                                        <InputLabel>IP</InputLabel>
                                        <Input
                                            value={bancoServidor}
                                            onChange={(event) => { setBancoServidor(event.target.value) }}
                                        />
                                    </FormControl>

                                    <FormControl className={classes.margin} fullWidth style={{ marginRight: 70, width: '7ch' }}>
                                        <InputLabel>Porta</InputLabel>
                                        <Input
                                            value={bancoPorta}
                                            onChange={(event) => { setBancoPorta(event.target.value) }}
                                        />
                                    </FormControl>

                                    <FormControl className={classes.margin} fullWidth style={{ marginRight: 60, width: '25ch' }}>
                                        <InputLabel>Nome Base de Dados</InputLabel>
                                        <Input
                                            value={bancoBase}
                                            onChange={(event) => { setBancoBase(event.target.value) }}
                                        />
                                    </FormControl>

                                    <FormControl className={classes.margin} fullWidth style={{ marginRight: 60, width: '25ch' }}>
                                        <InputLabel>Usuário Base de Dados</InputLabel>
                                        <Input
                                            value={bancoUsuario}
                                            onChange={(event) => { setBancoUsuario(event.target.value) }}
                                        />
                                    </FormControl>

                                    <FormControl className={classes.margin} fullWidth style={{ marginRight: 60, width: '25ch' }}>
                                        <InputLabel>Senha Base de Dados</InputLabel>
                                        <Input
                                            value={bancoSenha}
                                            onChange={(event) => { setBancoSenha(event.target.value) }}
                                            type={'password'}
                                        />
                                    </FormControl>



                                    {/* <FormControl fullWidth className={classes.margin}>
                                        <InputLabel>Mensagem</InputLabel>
                                        <Input
                                            minRows={6}
                                            multiline={true}
                                            value={mensagem}
                                            onChange={(event) => { setMensagem(event.target.value) }}
                                        />
                                    </FormControl> */}


                                </Grid>

                            </form>

                        </DialogContentText>
                        <DialogActions>
                            <Button
                                variant="contained"
                                style={{ color: 'white', backgroundColor: '#3085d6' }}
                                onClick={() => {
                                    setDescricao('')
                                    setErp('')
                                    setBancoTipo('')
                                    setBancoServidor('')
                                    setBancoBase('')
                                    setBancoPorta('')
                                    setBancoUsuario('')
                                    setBancoSenha('')
                                    setOpenCadastroDest(false)
                                }}>
                                cancelar
                            </Button>
                            <Button
                                variant="contained"
                                style={{ color: 'white', backgroundColor: '#E58934' }}
                                onClick={() => {
                                    AdicionaIntegracao()
                                }}>
                                Salvar
                            </Button>
                        </DialogActions>
                    </Dialog>

                </div>


                <Dialog
                    open={loading}
                    keepMounted
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title" style={{ fontSize: 16, textAlign: 'center' }} disableTypography={true}>{msgLoading}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">

                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <CircularProgress size={24} color={'primary'} />
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>






            </Box >

        </div >
    )
}

export default Integracoes