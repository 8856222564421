import React, { useContext } from "react"
import {
    makeStyles,
    AppBar,
    Toolbar,
    IconButton,
    Button,
    Switch,
    Tooltip
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { BiBarcodeReader } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase'
import { GlobalContext } from "../contexts/global";


const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        backgroundColor: theme.palette.background.dark,
        flexGrow: 1,
    },
    appBar: {
        boxShadow: 'none',
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: theme.palette.type === 'dark'
            ? theme.palette.background.paper
            : '#E58934'
    },
    logo: {
        height: 20,
    },
    drawer: {
        width: 240,
        flexShrink: 0,
    },
    drawerPaper: {
        width: 240,
        borderRight: 'none',
    },
    menuIcon: {
        // paddingRight: theme.spacing(5),
        paddingLeft: '24px',
    },
    icons: {
        paddingRight: theme.spacing(5),
    },
    grow: {
        flexGrow: 1,
    },
    listItemText: {
        fontSize: 14,
    },
    listItem: {
        paddingTop: 4,
        paddingBottom: 4,
        height: 50
    },
    subheader: {
        textTransform: 'uppercase',
    },
    paper: {
        width: '80%',
        maxHeight: 435,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));




function BarraSuperior() {

    const classes = useStyles();
    const theme = useTheme();
    const Navigate = useNavigate()



    const { conexao, usuario, qtdeTimes, registrarCodigoTime, registrarTimeAtual, timeAtual, codigoTime, salvarLogin, plano, GeraLogServidor } = useContext(GlobalContext)

    const [loading, setLoading] = React.useState(false);

    function Sair() {
        auth
            .signOut()
            .then(() => {
                Navigate("/")
                console.log('Deslogando da Conta')
                registrarCodigoTime(null)
                registrarTimeAtual(null)
                GeraLogServidor(usuario.email, 'DASH', 'BARRA SUPERIOR', 'Clicou em Sair')
            })
            .catch(error => alert(error.message))
    }



    return (
        <AppBar color='inherit' className={classes.appBar}>
            <Toolbar>
                <IconButton
                    edge='start'
                    className={classes.menuIcon}
                    aria-label='menu'
                // onClick={()=>{
                //     AbrirMenu()
                // }}
                >
                    < BiBarcodeReader size={24} color='#ffffff' className="icone-nav" />
                    {/* <MenuIcon /> */}
                </IconButton>
                <img
                    src={
                        theme.palette.type === 'dark'
                            ? '/images/preto.png'
                            : '/images/branco.png'
                    }
                    alt='logo'
                    className={classes.logo}
                />
                <div className={classes.grow} />
                <Button
                    variant="text"
                    style={{ color: 'white', marginRight: 20 }}
                    disabled={loading}
                >

                    {usuario.email}

                </Button>
                {plano.plano &&
                    <Tooltip title="Plano Atual">
                        <Button
                            variant="text"
                            style={{ color: 'white', marginRight: 20 }}
                            disabled={loading}
                            onClick={() => {
                                Navigate("/meu-plano")
                            }}
                        >

                            {plano.plano}

                        </Button>
                    </Tooltip>
                }

                <Button
                    variant="contained"
                    style={{ color: '#E58934', backgroundColor: '#F4F6F8' }}
                    disabled={loading}
                    onClick={() => {
                        Sair()
                    }}>

                    Sair

                </Button>

            </Toolbar>
        </AppBar>
    )
}

export default BarraSuperior