import React, { useState, useContext, useEffect } from "react"
import {
    makeStyles,
    Box,
    Toolbar,
    Grid,
    Typography,
    Button,
    Dialog,
    DialogContentText,
    DialogTitle,
    DialogActions,
    TextField,
    Checkbox,
    FormControlLabel,
    CircularProgress,
    DialogContent
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { GlobalContext } from "../contexts/global";
import { msgAviso, msgConfirmacao } from "../components/sweet";
import { auth, db } from '../firebase'
import { useNavigate } from 'react-router-dom';
import { TiDelete } from 'react-icons/ti'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Sidebar from "../components/sidebar";
import BarraSuperior from "../components/barra";

import DataTable from 'react-data-table-component';



const aviso = withReactContent(Swal)

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        backgroundColor: '#F4F6F8',
        flexGrow: 1,
        spacing: 4,
    },
    formulario: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: '#E58934',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#E58934',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#E58934',
            },
            '&:hover fieldset': {
                borderColor: '#E58934',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#E58934',
            },
        },
    },
}));

function Destinatarios() {

    useEffect(() => {

        console.log('Chegou no useEffect do Destinatarios')
        const conectado = auth.onAuthStateChanged(user => {
            if (user) {
            
                    salvarLogin(user.email)
                    // registraLogin(user.email, Device.deviceName)
                    ValidaUsuario(user.email)
                    console.log(user.email)

                    // CarregaColetas(user.email)
                    listarDestinatarios(user.email)
               
            } else {
                console.log('Sai Daqui')
                navigate("/")
            }
        })

        return conectado;



    }, [])

    function ValidaUsuario(usuarioInformado) {

        console.log('Validando Licença do Usuário')

        const url = conexao.endereco + "/ValidaUsuario?usuario=" + usuarioInformado

        console.log('Endereço Configurado: ' + url)

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("GET", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("Authorization", conexao.autenticacao)
        requisicao.send()

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
        }

        requisicao.onerror = function () {
            salvarDadosPlano('GRATUITO', '', '')
            console.log('Deu Erro: ' + this.responseText)
        }

        requisicao.onloadend = function () {
            console.log('Deu Certo!')
            console.log('RESPONSE: ' + this.response)
            let resposta = JSON.parse(this.response)
            console.log('StatusCode: ' + requisicao.status)
            if (requisicao.status === 200) {

                salvarDadosPlano(resposta.plano, resposta.vencimento, resposta.status)

                if (resposta.plano == 'GRATUITO' || resposta.plano == 'BASICO') {
                    console.log('Sai Daqui')
                    msgAviso('Plano ' + resposta.plano, 'Apenas o Plano Pró e Completo tem Acesso a Planos de Ação', 'info', 'OK')
                    navigate("/meu-plano")
                }

                registrarQtdeTimes(resposta.times)

            } else {
                salvarDadosPlano('GRATUITO', '', '')
            }
            console.log(resposta);
        }

    }


    async function DeletaDestinatario(idInformado) {

        aviso.fire({
            title: 'Atenção',
            text: 'Confirma Exclusão do Destinatário?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#E58934',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {

                setLoading(true)
                setMsgLoading('Excluindo Destinatário')

                const urlDelDestinatario = conexao.endereco + "/ExcluirDestinatario?id=" + idInformado

                let request = new XMLHttpRequest()
                request.timeout = 10000
                request.open("GET", urlDelDestinatario, true)
                request.setRequestHeader("Content-type", "application/json")
                request.setRequestHeader("Authorization", conexao.autenticacao)
                request.send(JSON.stringify())

                request.ontimeout = function () {
                    setLoading(false)
                    alert('Erro no Envio da Requisição', 'TimeOut')
                }

                request.onload = function () {

                    let resposta = JSON.stringify(this.status)
                    let respostaJson = JSON.parse(this.response)

                    setNome('')
                    setEmail('')
                    setGrupo('')
                    setTelefone('')
                    setSms('')
                    setWhatsapp('')

                    setLoading(false)
                    setOpenCadastroDest(false)

                    console.log('Resposta do Envio: ' + JSON.stringify(respostaJson.status))

                    msgAviso(respostaJson.status, '', 'success', 'OK')

                    listarDestinatarios(usuario.email)

                }

            }
        })


    }

    const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigate()

    const [listaDestinatarios, setListaDestinatarios] = useState([])

    const [openCadastroDest, setOpenCadastroDest] = useState(false)

    const [loading, setLoading] = useState(false)
    const [msgLoading, setMsgLoading] = useState('')


    const [nome, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [grupo, setGrupo] = useState('')
    const [telefone, setTelefone] = useState('')
    const [sms, setSms] = useState(false)
    const [whatsapp, setWhatsapp] = useState(false)

    const { conexao, usuario, qtdeTimes, registrarCodigoTime, registrarTimeAtual, timeAtual, codigoTime, salvarLogin, salvarDadosPlano, registrarQtdeTimes, plano } = useContext(GlobalContext)


    const colunasDestinatarios = [
        {
            name: 'Nome',
            selector: row => row.nome,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Grupo',
            selector: row => row.grupo,
            sortable: true,
        },
        {
            name: 'SMS',
            selector: row => row.sms,
            sortable: true,
        },
        {
            name: 'WhatsApp',
            selector: row => row.whatsapp,
            sortable: true,
        },
        {
            name: '',
            button: true,
            cell: row => (
                < a onClick={() => {


                    DeletaDestinatario(row.id)
                }} >
                    <TiDelete size="24" color="#d9534f" title="Excluir Destinatário" />
                </a>
            ),
        },
    ];

    async function AdicionaDestinatario() {

        setLoading(true)
        setMsgLoading('Cadastrando Destinatário')

        const urlAddDestinatario = conexao.endereco + "/AdicionaDestinatario"

        let body = {
            "usuario": usuario.email,
            "time": '0',
            "nome": nome,
            "email": email,
            "grupo": grupo,
            "telefone": telefone,
            "sms": sms,
            "whatsapp": whatsapp
        }

        console.log(body)

        let request = new XMLHttpRequest()
        request.timeout = 10000
        request.open("POST", urlAddDestinatario, true)
        request.setRequestHeader("Content-type", "application/json")
        request.setRequestHeader("Authorization", conexao.autenticacao)
        request.send(JSON.stringify(body))

        request.ontimeout = function () {
            setLoading(false)
            alert('Erro no Envio do Cadastro', 'TimeOut')
        }

        request.onload = function () {

            let resposta = JSON.stringify(this.status)
            let respostaJson = JSON.parse(this.response)

            setNome('')
            setEmail('')
            setGrupo('')
            setTelefone('')
            setSms('')
            setWhatsapp('')

            setLoading(false)
            setOpenCadastroDest(false)

            console.log('Resposta do Envio: ' + JSON.stringify(respostaJson.status))

            msgAviso(respostaJson.status, '', 'success', 'OK')

            listarDestinatarios(usuario.email)

        }


    }

    function listarDestinatarios(emailInformado) {

        setLoading(true)
        setMsgLoading('Listando Destinatários')

        let url = conexao.endereco + '/ListaDestinatarios?usuario=' + emailInformado

        // if (codigoTime) {
        //     url = url + '&time=' + codigoTime
        // }

        console.log('Request Enviado: ' + url)

        let request = new XMLHttpRequest()
        request.timeout = 10000
        request.open("GET", url, true)
        request.setRequestHeader("Content-Type", "application/json")
        request.setRequestHeader("Authorization", conexao.autenticacao)
        request.send()

        request.ontimeout = function () {
            msgAviso('Atenção', 'TimeOut na Conexão', 'warning', 'OK')
            setLoading(false)
        }

        request.onload = function () {

            setLoading(false)

            let codigoResposta = JSON.stringify(this.status)
            let textoResposta = JSON.parse(this.response)

            console.log('Resposta Antes do StatusCode: ' + textoResposta)
            console.log(codigoResposta)

            if (codigoResposta != 200) {

                msgAviso('Atenção', 'Acesso Negado', 'error', 'ok');
                console.log(textoResposta)

            } else {

                setListaDestinatarios(textoResposta)
                console.log('Busca Finalizada com Sucesso!')

            }
        }
    }


    return (
        <div className={classes.root}>
            <BarraSuperior />
            <Box display='flex'>
                <Sidebar />
                <div className={classes.root}>
                    <Box p={8}>
                        <Toolbar />
                        <Grid container spacing={3}>
                            <Grid item xs={3} >
                                <Typography
                                    component={'span'}
                                    variant='h5'
                                    color='textPrimary'
                                    style={{ fontWeight: 600 }}
                                >
                                    Plano de Ação
                                </Typography>

                                <Typography
                                    variant='h6'
                                    color='textPrimary'
                                    style={{ fontWeight: 400 }}
                                >
                                    Destinatários
                                </Typography>
                            </Grid>
                            <Grid item xs={9} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                <Button
                                    variant="contained"
                                    // color="primary"
                                    style={{ color: 'white', backgroundColor: '#E58934' }}
                                    onClick={() => {
                                        setOpenCadastroDest(true)
                                    }}>
                                    novo destinatário
                                </Button>
                            </Grid>

                        </Grid>

                        <br />

                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <DataTable
                                    columns={colunasDestinatarios}
                                    data={listaDestinatarios}
                                    // onRowClicked={(row, index) => { CarregaDetalheColetas(row.departamento, row.vencimento) }}
                                    striped={true}
                                    highlightOnHover={true}
                                    responsive={true}
                                    pointerOnHover={true}
                                    noDataComponent={"Nenhum Registro Encontrado =/"}
                                />
                            </Grid>
                        </Grid>

                    </Box>


                    <Dialog
                        open={openCadastroDest}
                        keepMounted
                        fullWidth
                        maxWidth={'md'}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                        onClose={() => {
                            setOpenCadastroDest(false)
                        }}
                    >
                        <DialogTitle id="alert-dialog-slide-title" style={{ fontSize: 18, textAlign: 'center', color: '#E58934' }} disableTypography={true}>{'Destinatário'}</DialogTitle>

                        <DialogContentText id="alert-dialog-slide-description" >

                            <form className={classes.formulario} noValidate autoComplete="off">
                                <Grid xs={12} container spacing={1} style={{ height: 280, padding: 30 }}>
                                    <Grid item xs={4}  >
                                        <TextField
                                            id="standard-basic"
                                            label="Nome"
                                            value={nome}
                                            color="primary"
                                            onChange={(event) => { setNome(event.target.value) }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}  >
                                        <TextField
                                            id="standard-full-width"
                                            label="Endereço de E-mail"
                                            value={email}
                                            onChange={(event) => { setEmail(event.target.value) }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}  >
                                        <TextField
                                            id="standard-basic"
                                            label="Grupo"
                                            value={grupo}
                                            onChange={(event) => { setGrupo(event.target.value) }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}  >
                                        <TextField
                                            id="standard-basic"
                                            label="Telefone (com DDD)"
                                            value={telefone}
                                            onChange={(event) => { setTelefone(event.target.value) }}
                                        />
                                    </Grid>
                                    <Grid item xs={8}  >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={sms}
                                                    disabled={true}
                                                    onChange={(event) => { setSms(!sms) }}
                                                    name="checkSMS"
                                                    style={{ color: '#E58934' }}
                                                />
                                            }
                                            label="Enviar SMS (Em Breve)"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={whatsapp}
                                                    onChange={(event) => { setWhatsapp(!whatsapp) }}
                                                    name="checkWhatsApp"
                                                    style={{ color: '#E58934' }}
                                                />
                                            }
                                            label="Enviar WhatsApp (Apenas no Plano Completo)"
                                        />
                                    </Grid>

                                </Grid>

                            </form>

                        </DialogContentText>
                        <DialogActions>
                            <Button
                                variant="contained"
                                style={{ color: 'white', backgroundColor: '#3085d6' }}
                                onClick={() => {
                                    setNome('')
                                    setEmail('')
                                    setGrupo('')
                                    setTelefone('')
                                    setWhatsapp('')
                                    setSms('')
                                    setOpenCadastroDest(false)
                                }}>
                                cancelar
                            </Button>
                            <Button
                                variant="contained"
                                style={{ color: 'white', backgroundColor: '#E58934' }}
                                onClick={() => {
                                    AdicionaDestinatario()
                                }}>
                                Salvar
                            </Button>
                        </DialogActions>
                    </Dialog>

                </div>


                <Dialog
                    open={loading}
                    keepMounted
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title" style={{ fontSize: 16, textAlign: 'center' }} disableTypography={true}>{msgLoading}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">

                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <CircularProgress size={24} color={'primary'} />
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>






            </Box >

        </div >
    )
}

export default Destinatarios