import React, { useState, useContext } from 'react';
import {
    makeStyles,
    AppBar,
    Toolbar,
    IconButton,
    Drawer,
    Button,
    List,
    Typography,
    Divider,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Box,
    Grid,
    Hidden,
    Switch,
    CircularProgress,
    RadioGroup,
    Radio,
    DialogActions,
    FormControlLabel,
    Tabs,
    Tab,
    Paper,
    Badge,
    Accordion,
    AccordionSummary,
    Fab
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';

import { GlobalContext } from "../contexts/global";

import HomeIcon from '@material-ui/icons/Home';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';

import DoneAllIcon from '@material-ui/icons/DoneAll';
import StorefrontIcon from '@material-ui/icons/Storefront';
import ReceiptIcon from '@material-ui/icons/Receipt';
import CodeIcon from '@material-ui/icons/Code';
import SettingsIcon from '@material-ui/icons/Settings';
import HelpIcon from '@material-ui/icons/Help';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        backgroundColor: theme.palette.background.dark,
        flexGrow: 1,
    },
    fab: {
        position: 'absolute',
        zIndex: 1,
        bottom: theme.spacing(3),
        right: theme.spacing(3),
    },
    rootAc: {
        width: '100%'
    },
    appBar: {
        boxShadow: 'none',
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: theme.palette.type === 'dark'
            ? theme.palette.background.paper
            : '#E58934'
    },
    logo: {
        height: 20,
    },
    drawer: {
        width: 240,
        // flexShrink: 0,
    },
    drawerPaper: {
        width: 240,
        borderRight: 'none',
    },
    icons: {
        paddingRight: theme.spacing(5),
    },
    grow: {
        flexGrow: 1,
    },
    listItemText: {
        fontSize: 14,
    },
    listItem: {
        paddingTop: 4,
        paddingBottom: 4,
        height: 50
    },
    subheader: {
        textTransform: 'uppercase',
    },
    paper: {
        width: '80%',
        maxHeight: 435,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));


function Sidebar() {

    const [openCadastros, setOpenCadastros] = React.useState(false);
    const [openGestao, setOpenGestao] = React.useState(false);
    const [openPlano, setOpenPlano] = React.useState(false);

    const abrirMenuCadastros = () => {
        setOpenGestao(false)
        setOpenPlano(false)
        setOpenCadastros(!openCadastros);
    };
    const abrirMenuGestao = () => {
        setOpenCadastros(false)
        setOpenPlano(false)
        setOpenGestao(!openGestao);
    };
    const abrirMenuPlano = () => {
        setOpenCadastros(false)
        setOpenGestao(false)
        setOpenPlano(!openPlano);
    };

    const classes = useStyles();
    const theme = useTheme();

    const Navigate = useNavigate()

    const { conexao, usuario, qtdeTimes, registrarCodigoTime, registrarTimeAtual, timeAtual, codigoTime, salvarLogin, salvarDadosPlano, registrarQtdeTimes, GeraLogServidor } = useContext(GlobalContext)

    const [menuAberto, setMenuAberto] = React.useState(false)
    function AbrirMenu() {
        setMenuAberto(!menuAberto)
    }



    return (
        <Hidden mdDown>
            <Drawer
                className={classes.drawer}
                variant='permanent'
                open={menuAberto}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <Toolbar />
                <div className={classes.drawerContainer} >
                    <List >
                        <ListItem button classes={{ root: classes.listItem }} onClick={() => {
                            GeraLogServidor(usuario.email, 'DASH', 'MENU', 'Clicou em Dashboard')
                            Navigate("/dashboard")
                        }}>
                            <ListItemIcon>{<HomeIcon />}</ListItemIcon>
                            <ListItemText
                                classes={{
                                    primary: classes.listItemText,
                                }}
                                primary={'Dashboard'}
                            />
                        </ListItem>
                        <Divider />
                        <ListItem button classes={{ root: classes.listItem }} onClick={() => {
                            GeraLogServidor(usuario.email, 'DASH', 'MENU', 'Clicou em Vencimentos')
                            Navigate("/validades")
                        }}>
                            <ListItemIcon>{<AssignmentLateIcon />}</ListItemIcon>
                            <ListItemText
                                classes={{
                                    primary: classes.listItemText,
                                }}
                                primary={'Vencimentos'}
                            />
                        </ListItem>
                        <Divider />
                        <ListItem button classes={{ root: classes.listItem }} onClick={abrirMenuCadastros}>
                            <ListItemIcon>{<StorefrontIcon />}</ListItemIcon>
                            <ListItemText
                                classes={{
                                    primary: classes.listItemText,
                                }}
                                primary={'Cadastros'}
                            />
                            {openCadastros ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={openCadastros} timeout="auto" unmountOnExit>
                            <List >
                                <ListItem button classes={{ root: classes.listItem }}>
                                    <ListItemIcon><ArrowRightIcon /></ListItemIcon>
                                    <ListItemText
                                        classes={{
                                            primary: classes.listItemText,
                                        }}
                                        primary="Produtos" />
                                </ListItem>
                                <ListItem button classes={{ root: classes.listItem }}>
                                    <ListItemIcon><ArrowRightIcon /></ListItemIcon>
                                    <ListItemText
                                        classes={{
                                            primary: classes.listItemText,
                                        }}
                                        primary="Departamentos" />
                                </ListItem>
                            </List>
                        </Collapse>
                        <ListItem button classes={{ root: classes.listItem }} onClick={() => {
                            GeraLogServidor(usuario.email, 'DASH', 'MENU', 'Clicou em Relatórios')
                            Navigate("/relatorios")
                        }}>
                            <ListItemIcon>{<ReceiptIcon />}</ListItemIcon>
                            <ListItemText
                                classes={{
                                    primary: classes.listItemText,
                                }}
                                primary={'Relatórios'}
                            />
                        </ListItem>
                    </List>
                    <ListItem button classes={{ root: classes.listItem }} onClick={abrirMenuPlano}>
                        <ListItemIcon>{<DoneAllIcon />}</ListItemIcon>
                        <ListItemText
                            classes={{
                                primary: classes.listItemText,
                            }}
                            primary={'Plano de Ação'}
                        />
                        {openPlano ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openPlano} timeout="auto" unmountOnExit>
                        <List >
                            <ListItem button classes={{ root: classes.listItem }} onClick={() => {
                                GeraLogServidor(usuario.email, 'DASH', 'MENU', 'Clicou em Plano de Ação --> Destinatários')
                                Navigate("/destinatarios")
                            }}>
                                <ListItemIcon><ArrowRightIcon /></ListItemIcon>
                                <ListItemText
                                    classes={{
                                        primary: classes.listItemText,
                                    }}
                                    primary="Destinarários" />
                            </ListItem>
                            <ListItem button classes={{ root: classes.listItem }} onClick={() => {
                                GeraLogServidor(usuario.email, 'DASH', 'MENU', 'Clicou em Plano de Ação --> Ações')
                                Navigate("/acoes")
                            }}>
                                <ListItemIcon><ArrowRightIcon /></ListItemIcon>
                                <ListItemText
                                    classes={{
                                        primary: classes.listItemText,
                                    }}
                                    primary="Ações" />
                            </ListItem>
                        </List>
                    </Collapse>
                    <Divider />
                    <List>
                        <ListItem button classes={{ root: classes.listItem }} onClick={() => {
                            GeraLogServidor(usuario.email, 'DASH', 'MENU', 'Clicou em Integrações')
                            Navigate("/integracoes")
                        }}>
                            <ListItemIcon>
                                <CodeIcon />
                            </ListItemIcon>
                            <ListItemText
                                classes={{
                                    primary: classes.listItemText,
                                }}
                                primary={'Integrações'}
                            />
                        </ListItem>
                        <ListItem button classes={{ root: classes.listItem }} onClick={() => {
                            GeraLogServidor(usuario.email, 'DASH', 'MENU', 'Clicou em Configurações')
                            Navigate("/configuracoes")
                        }}>
                            <ListItemIcon>
                                <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText
                                classes={{
                                    primary: classes.listItemText,
                                }}
                                primary={'Configurações'}
                            />
                        </ListItem>
                    </List>
                    {/* <Box p={7}>
                    <Typography variant='body2'>
                        Faça login para curtur vídeos, comentar e se inscrever.
                    </Typography>
                    <Box mt={2}>
                        <Button
                            variant='outlined'
                            color='secondary'
                            startIcon={<AccountCircle />}
                        >
                            Fazer login
                        </Button>
                    </Box>
                </Box> */}

                    <Divider />

                    <ListItem button classes={{ root: classes.listItem }} onClick={abrirMenuGestao}>
                        <ListItemIcon>{<AssignmentTurnedInIcon />}</ListItemIcon>
                        <ListItemText
                            classes={{
                                primary: classes.listItemText,
                            }}
                            primary={'Gestão'}
                        />
                        {openGestao ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openGestao} timeout="auto" unmountOnExit>
                        <List >
                            <ListItem button classes={{ root: classes.listItem }}>
                                <ListItemIcon><ArrowRightIcon /></ListItemIcon>
                                <ListItemText
                                    classes={{
                                        primary: classes.listItemText,
                                    }}
                                    primary="Minha Conta" />
                            </ListItem>
                            <ListItem button classes={{ root: classes.listItem }} onClick={() => {
                                GeraLogServidor(usuario.email, 'DASH', 'MENU', 'Clicou em Meu Plano')
                                Navigate("/meu-plano")
                            }}>
                                <ListItemIcon><ArrowRightIcon /></ListItemIcon>
                                <ListItemText
                                    classes={{
                                        primary: classes.listItemText,
                                    }}
                                    primary="Meu Plano" />
                            </ListItem>
                            <ListItem button classes={{ root: classes.listItem }}>
                                <ListItemIcon><ArrowRightIcon /></ListItemIcon>
                                <ListItemText
                                    classes={{
                                        primary: classes.listItemText,
                                    }}
                                    primary="Meus Times" />
                            </ListItem>
                            {usuario.email == 'contato@outbox.inf.br' &&
                                <ListItem button classes={{ root: classes.listItem }} onClick={() => { Navigate("/administracao") }}>
                                    <ListItemIcon><ArrowRightIcon /></ListItemIcon>
                                    <ListItemText
                                        classes={{
                                            primary: classes.listItemText,
                                        }}
                                        primary="Administração" />
                                </ListItem>
                            }

                        </List>
                    </Collapse>




                    <Divider />
                    <ListItem button classes={{ root: classes.listItem }} onClick={() => {
                        window.open('https://www.youtube.com/channel/UCPKNsd_CuuFvKORS6rTSEEQ', '_blank');
                        GeraLogServidor(usuario.email, 'DASH', 'MENU', 'Clicou em Ajuda')
                    }}>
                        <ListItemIcon>
                            <HelpIcon />
                        </ListItemIcon>
                        <ListItemText
                            classes={{
                                primary: classes.listItemText,
                            }}
                            primary={'Obter Ajuda'}
                        />
                    </ListItem>
                    <Divider />
                </div>
            </Drawer>
        </Hidden>
    )
}

export default Sidebar