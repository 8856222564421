import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import GlobalProvider from './contexts/global';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Login from './pages/login';
import Dashboard from './pages/dashboard';
import Destinatarios from './pages/plano-destinatarios';
import Erro from './pages/erro';
import Acoes from './pages/plano-acoes';
import Integracoes from './pages/integracoes';
import Charts from './pages/charts';
import Relatorios from './pages/relatorios';
import MeuPlano from './pages/meu-plano';
import Administracao from './pages/administracao'
import Configuracoes from './pages/configuracoes';
import AdmUsuarios from './pages/admin-usuarios';

function App() {

  

  return (

    <div>
      <GlobalProvider>
        <BrowserRouter>

          <Routes>

            <Route exact path="/" element={<Login />} />
            <Route exact path="/validades" element={<Dashboard />} />
            <Route exact path="/dashboard" element={<Charts />} />
            <Route exact path="/destinatarios" element={<Destinatarios />} />
            <Route exact path="/acoes" element={<Acoes />} />
            <Route exact path="/integracoes" element={<Integracoes />} />
            <Route exact path="/relatorios" element={<Relatorios />} />
            <Route exact path="/meu-plano" element={<MeuPlano />} />
            <Route exact path="/administracao" element={<Administracao />} />
            <Route exact path="/administracao/usuarios" element={<AdmUsuarios />} />
            <Route exact path="/configuracoes" element={<Configuracoes />} />
            {/* <Route exact path="/relatorios/:id" element={<Visualizador />} /> */}
            <Route exact path="*" element={<Erro />} />


          </Routes>

        </BrowserRouter>
      </GlobalProvider>
    </div >

  );
}

export default App;
