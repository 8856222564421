import React, { useState, useContext, useEffect } from "react"
import {
    makeStyles,
    Box,
    Toolbar,
    Grid,
    Typography,
    Button,
    Dialog,
    DialogContentText,
    DialogTitle,
    DialogActions,
    TextField,
    TextareaAutosize,
    Checkbox,
    FormControlLabel,
    CircularProgress,
    DialogContent,
    InputLabel,
    Input,
    FormControl,
    Tooltip,
    AccordionSummary,
    AccordionDetails,
    Accordion,
    ListItem,
    ListItemText,
    Tab,
    Tabs
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from "moment";
import { useTheme, withStyles } from '@material-ui/core/styles';
import { GlobalContext } from "../contexts/global";
import { msgAviso, msgConfirmacao } from "../components/sweet";
import { auth, db } from '../firebase'
import { useNavigate, useParams } from 'react-router-dom';
import { collection, getDocs, query, where, deleteDoc, doc, updateDoc, addDoc, orderBy } from "firebase/firestore";
import PropTypes from 'prop-types';
import RefreshIcon from '@material-ui/icons/Refresh';
import InfoIcon from '@material-ui/icons/Info';

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

import { getAuth, signInWithEmailAndPassword } from "firebase/auth";


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Sidebar from "../components/sidebar";
import BarraSuperior from "../components/barra";

import DataTable from 'react-data-table-component';
import { now } from "lodash";



const aviso = withReactContent(Swal)

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        backgroundColor: '#F4F6F8',
        flexGrow: 1,
        spacing: 4,
    },
    rootAc: {
        width: '100%',
    },
    listItemText: {
        // fontSize: 18,
        padding: 0,
        transition: '0.3s',
        '&:hover': {
            fontWeight: 'bold'
        }
    },
    linha: {
        width: '100%',
    },
    listItem: {
        paddingTop: 4,
        paddingBottom: 4,
        height: 50,
        transition: '0.3s',
        '&:hover': {
            paddingLeft: 25,
        }
    },
    margin: {
        margin: theme.spacing(1),
    },
    heading: {
        fontSize: 18,
        fontWeight: 'bold'
    },
    formulario: {
        display: 'flex',
        flexWrap: 'wrap',
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: '#E58934',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#E58934',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#E58934',
            },
            '&:hover fieldset': {
                borderColor: '#E58934',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#E58934',
            },
        },
    },
}));



function Configuracoes({ navigation, route }) {



    const classes = useStyles();
    const theme = useTheme();
    const Navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [msgLoading, setMsgLoading] = useState('')

    const [expanded, setExpanded] = React.useState(false);

    const [primeiroHorario, setPrimeiroHorario] = useState('')
    const [segundoHorario, setSegundoHorario] = useState('')
    const [horarioMaximoIntegracao, setHorarioMaximoIntegracao] = useState('')
    const [emailNotificar, setEmailNotificar] = useState('')
    const [diasAvisoVencimento, setDiasAvisoVencimento] = useState('')

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const { id } = useParams();


    const { conexao, usuario, qtdeTimes, registrarCodigoTime, registrarTimeAtual, timeAtual, codigoTime, salvarLogin, salvarDadosPlano, registrarQtdeTimes } = useContext(GlobalContext)

    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
            title: ''
        },
    }))(Tooltip);


    useEffect(() => {
        console.log('Chegou no useEffect do Administracao')
        const conectado = auth.onAuthStateChanged(user => {
            if (user) {

                salvarLogin(user.email)
                ValidaUsuario(user.email)
                ReceberConfiguracoes(user.email)
                console.log(user.email)


            } else {
                console.log('Sai Daqui')
                Navigate("/?origem=configuracoes")
            }
        })

    }, [])

    function ValidaUsuario(usuarioInformado) {

        console.log('Validando Licença do Usuário')

        const url = conexao.endereco + "/ValidaUsuario?usuario=" + usuarioInformado

        console.log('Endereço Configurado: ' + url)

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("GET", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("Authorization", conexao.autenticacao)
        requisicao.send()

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
        }

        requisicao.onerror = function () {
            salvarDadosPlano('GRATUITO', '', '')
            console.log('Deu Erro: ' + this.responseText)
        }

        requisicao.onloadend = function () {
            console.log('Deu Certo!')
            console.log('RESPONSE: ' + this.response)
            let resposta = JSON.parse(this.response)
            console.log('StatusCode: ' + requisicao.status)
            if (requisicao.status === 200) {

                salvarDadosPlano(resposta.plano, resposta.vencimento, resposta.status)

                // if (resposta.plano == 'GRATUITO') {
                //     navigate("/meu-plano")
                // }

                registrarQtdeTimes(resposta.times)


            } else {
                salvarDadosPlano('GRATUITO', '', '')
            }
            console.log(resposta);
        }

    }

    function EnviarConfiguracoes(usuarioInformado) {

        console.log('Enviando Configurações')
        setLoading(true)
        setMsgLoading('Enviando Configurações')

        const url = conexao.endereco + "/UpdateConfig"

        console.log('Endereço Configurado: ' + url)

        let body = {
            "usuario": usuarioInformado,
            "conf_notif_hora1": primeiroHorario,
            "conf_notif_hora2": segundoHorario,
            "conf_integ_horario_maximo": horarioMaximoIntegracao,
            "conf_integ_email_avisar": emailNotificar,
            "conf_aviso_dias": diasAvisoVencimento,
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("Authorization", conexao.autenticacao)
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onloadend = function () {
            setLoading(false)
            console.log('Deu Certo!')
            console.log('RESPONSE: ' + this.response)
            let resposta = JSON.parse(this.response)
            console.log('StatusCode: ' + requisicao.status)

            msgAviso('Parabéns', resposta.status, 'success', 'OK')

            console.log(resposta);
        }

    }

    function ReceberConfiguracoes(usuarioInformado) {

        console.log('Recebendo Configurações')
        setLoading(true)
        setMsgLoading('Recebendo Configurações')

        const url = conexao.endereco + "/GetConfig?usuario=" + usuarioInformado

        console.log('Endereço Configurado: ' + url)

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("GET", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("Authorization", conexao.autenticacao)
        requisicao.send()

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onloadend = function () {
            setLoading(false)
            console.log('Deu Certo!')
            console.log('RESPONSE: ' + this.response)
            let resposta = JSON.parse(this.response)
            console.log('StatusCode: ' + requisicao.status)

            setPrimeiroHorario(resposta.conf_notif_hora1)
            setSegundoHorario(resposta.conf_notif_hora2)
            setHorarioMaximoIntegracao(resposta.conf_integ_horario_maximo)
            setEmailNotificar(resposta.conf_integ_email_avisar)
            setDiasAvisoVencimento(resposta.conf_aviso_dias)

            console.log(resposta);
        }

    }

    const colunasColetasUsuarios = [
        {
            name: 'Usuário',
            selector: row => row.usuario
        },
        {
            name: 'Coletas',
            selector: row => row.coletas
        },
        {
            name: 'Produtos',
            selector: row => row.produtos
        },
        {
            name: 'Departamentos',
            selector: row => row.departamentos
        },
        {
            name: 'Última Coleta',
            selector: row => row.ultima_coleta
        },
    ];

    const colunasColetasTimes = [
        // {
        //     name: 'Código Time',
        //     selector: row => row.id_time
        // },
        {
            name: 'Time',
            selector: row => row.time
        },
        {
            name: 'Proprietário',
            selector: row => row.proprietario
        },
        {
            name: 'Coletas',
            selector: row => row.coletas
        },
        {
            name: 'Produtos',
            selector: row => row.produtos
        },
        {
            name: 'Departamentos',
            selector: row => row.departamentos
        },
        {
            name: 'Última Coleta',
            selector: row => row.ultima_coleta
        },
    ];

    const colunasLogsAcesso = [
        // {
        //     name: 'ID',
        //     selector: row => row.id
        // },
        {
            name: 'Origem',
            selector: row => row.origem
        },
        {
            name: 'Tipo',
            selector: row => row.tipo
        },
        {
            name: 'Complemento',
            selector: row => row.complemento,
            wrap: true
        },
        {
            name: 'Data',
            selector: row => row.data
        },
        {
            name: 'Hora',
            selector: row => row.hora
        },
        // {
        //     name: 'Uusário',
        //     selector: row => row.usuario
        // },
    ];




    return (
        <div className={classes.root}>
            <BarraSuperior />
            <Box display='flex'>
                <Sidebar />
                <div className={classes.root}>
                    <Box p={8} style={{ backgroundColor: '#F4F6F8', paddingBottom: 60 }}>
                        <Toolbar />
                        <Grid container spacing={3}>
                            <Grid item xs={3} >

                                <Typography
                                    component={'span'}
                                    variant='h5'
                                    color='textPrimary'
                                    style={{ fontWeight: 600 }}
                                >
                                    Configurações
                                    {/* <RefreshIcon size="36" title="Excluir Tarefa" style={{ cursor: 'hand', color: 'green' }} onClick={() => {
                                        CarregaStats()
                                        setTabSelecionada(0)
                                    }} /> */}
                                </Typography>
                            </Grid>
                            <Grid item xs={9} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }} >
                                <Button
                                    variant="contained"
                                    style={{ backgroundColor: '#E58934', color: 'white' }}
                                    disabled={loading}
                                    onClick={() => {
                                        EnviarConfiguracoes(usuario.email)
                                    }}>
                                    Salvar
                                </Button>
                            </Grid>



                        </Grid>
                        <hr />
                        <br />

                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <div className={classes.rootAc}>
                                    <form className={classes.formulario} noValidate autoComplete="off">

                                        <Typography
                                            component={'span'}
                                            variant='h6'
                                            color='textPrimary'
                                            style={{ fontWeight: 400 }}
                                        >
                                            Notificações do Plano de Ação

                                        </Typography>

                                        <div style={{ width: 30, height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: 20, color: '#E58934' }}>
                                            <LightTooltip placement="right-start" title={
                                                <React.Fragment>
                                                    <Typography style={{ color: '#E58934' }}>Notificações do Plano de Ação</Typography>
                                                    <br />
                                                    <div style={{ color: 'black', fontSize: 12 }}>
                                                        Você pode escolher até 2 horários para serem enviadas as notificações dos planos de ação, Whatsapp e E-mail
                                                    </div>

                                                </React.Fragment>
                                            }>
                                                <InfoIcon size="36" />
                                            </LightTooltip>
                                        </div>



                                        <Grid sm={12} xs={12} md={12} lg={12} container spacing={1} style={{ marginBottom: 40, paddingTop: 30 }}>

                                            <FormControl className={classes.margin} style={{ width: 200 }}>
                                                <InputLabel>Primeiro Horário Envio </InputLabel>
                                                <Input
                                                    type="time"
                                                    value={primeiroHorario}
                                                    onChange={(event) => { setPrimeiroHorario(event.target.value) }}
                                                />
                                            </FormControl>
                                            <FormControl className={classes.margin} style={{ width: 200, marginLeft: 30 }}>
                                                <InputLabel>Segundo Horário Envio </InputLabel>
                                                <Input
                                                    type="time"
                                                    value={segundoHorario}
                                                    onChange={(event) => { setSegundoHorario(event.target.value) }}
                                                />
                                            </FormControl>

                                        </Grid>

                                        <Typography
                                            component={'span'}
                                            variant='h6'
                                            color='textPrimary'
                                            style={{ fontWeight: 400 }}
                                        >
                                            Integração com ERP

                                        </Typography>

                                        <div style={{ width: 30, height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: 20, color: '#E58934' }}>
                                            <LightTooltip placement="right-start" title={
                                                <React.Fragment>
                                                    <Typography style={{ color: '#E58934' }}>Integração com ERP</Typography>
                                                    <br />
                                                    <div style={{ color: 'black', fontSize: 12 }}>
                                                        Você pode definir um horário limite para receber a integração, caso passe este horário e a integração não tenha sido executada, um e-mail será enviado avisando
                                                    </div>

                                                </React.Fragment>
                                            }>
                                                <InfoIcon size="36" />
                                            </LightTooltip>
                                        </div>

                                        <Grid sm={12} xs={12} md={12} lg={12} container spacing={1} style={{ marginBottom: 40, paddingTop: 30 }}>

                                            <FormControl className={classes.margin} style={{ width: 200 }}>
                                                <InputLabel>Horário Máximo </InputLabel>
                                                <Input
                                                    type="time"
                                                    value={horarioMaximoIntegracao}
                                                    onChange={(event) => { setHorarioMaximoIntegracao(event.target.value) }}
                                                />
                                            </FormControl>

                                            <FormControl className={classes.margin} style={{ width: 300, marginLeft: 30 }}>
                                                <InputLabel>E-mail para Notificar </InputLabel>
                                                <Input
                                                    type="email"
                                                    value={emailNotificar}
                                                    onChange={(event) => { setEmailNotificar(event.target.value) }}
                                                />
                                            </FormControl>


                                        </Grid>

                                        <Typography
                                            component={'span'}
                                            variant='h6'
                                            color='textPrimary'
                                            style={{ fontWeight: 400 }}
                                        >
                                            Aviso de Vencimento

                                        </Typography>

                                        <div style={{ width: 30, height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: 20, color: '#E58934' }}>
                                            <LightTooltip placement="right-start" title={
                                                <React.Fragment>
                                                    <Typography style={{ color: '#E58934' }}> Aviso de Vencimento</Typography>
                                                    <br />
                                                    <div style={{ color: 'black', fontSize: 12 }}>
                                                        Escolha a quantidade de dias antes do vencimento que você deseja ser notificado
                                                    </div>

                                                </React.Fragment>
                                            }>
                                                <InfoIcon size="36" />
                                            </LightTooltip>
                                        </div>

                                        <Grid sm={12} xs={12} md={12} lg={12} container spacing={1} style={{ marginBottom: 40, paddingTop: 30 }}>

                                            <FormControl className={classes.margin} style={{ width: 200 }}>
                                                <InputLabel>Dias Antes do Vencimento </InputLabel>
                                                <Input
                                                    type="number"
                                                    value={diasAvisoVencimento}
                                                    onChange={(event) => { setDiasAvisoVencimento(event.target.value) }}
                                                />
                                            </FormControl>


                                        </Grid>


                                    </form>
                                </div>


                            </Grid>
                        </Grid>

                    </Box>




                </div>


                <Dialog
                    open={loading}
                    keepMounted
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title" style={{ fontSize: 16, textAlign: 'center' }} disableTypography={true}>{msgLoading}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">

                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <CircularProgress size={24} color={'primary'} />
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>






            </Box >

        </div >
    )
}

export default Configuracoes