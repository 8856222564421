import React, { useState, useContext, useEffect } from "react"
import {
    makeStyles,
    Box,
    Toolbar,
    Grid,
    Typography,
    Button,
    Dialog,
    DialogContentText,
    DialogTitle,
    DialogActions,
    TextField,
    TextareaAutosize,
    Checkbox,
    FormControlLabel,
    CircularProgress,
    DialogContent,
    InputLabel,
    Input,
    FormControl,
    Tooltip,
    AccordionSummary,
    AccordionDetails,
    Accordion,
    ListItem,
    ListItemText,
    Tab,
    Tabs,
    Card,
    CardContent
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from "moment";
import { useTheme } from '@material-ui/core/styles';
import { GlobalContext } from "../contexts/global";
import { msgAviso, msgConfirmacao } from "../components/sweet";
import { auth, db } from '../firebase'
import { useNavigate, useParams } from 'react-router-dom';
import { collection, getDocs, query, where, deleteDoc, doc, updateDoc, addDoc, orderBy } from "firebase/firestore";
import PropTypes from 'prop-types';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

import { getAuth, signInWithEmailAndPassword } from "firebase/auth";


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Sidebar from "../components/sidebar";
import BarraSuperior from "../components/barra";

import DataTable from 'react-data-table-component';
import { now } from "lodash";
import { Col, Row } from 'react-bootstrap'



const aviso = withReactContent(Swal)

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        backgroundColor: '#F4F6F8',
        flexGrow: 1,
        spacing: 4,
    },
    rootAc: {
        width: '100%',
    },
    listItemText: {
        // fontSize: 18,
        padding: 0,
        transition: '0.3s',
        '&:hover': {
            fontWeight: 'bold'
        }
    },
    linha: {
        width: '100%',
    },
    card: {
        minWidth: '40%',
        margin: 20,
        transition: '0.3s',
        cursor: 'pointer',
        '&:hover': {
            transform: 'scale(1.15)',
        }
    },
    listItem: {
        paddingTop: 4,
        paddingBottom: 4,
        height: 50,
        transition: '0.3s',
        '&:hover': {
            paddingLeft: 25,
        }
    },
    number: {
        marginTop: 25,
        fontSize: 72,
    },
    pos: {
        marginTop: 12,
        textAlign: 'center',
        // backgroundColor: 'red'
    },
    title: {
        fontSize: 14,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    margin: {
        margin: theme.spacing(1),
    },
    heading: {
        fontSize: 18,
        fontWeight: 'bold'
    },
    formulario: {
        display: 'flex',
        flexWrap: 'wrap',
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: '#E58934',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#E58934',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#E58934',
            },
            '&:hover fieldset': {
                borderColor: '#E58934',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#E58934',
            },
        },
    },
}));



function AdmUsuarios() {

    const classes = useStyles();
    const theme = useTheme();
    const Navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [msgLoading, setMsgLoading] = useState('')

    return (
        <div className={classes.root}>
            <BarraSuperior />
            <Box display='flex'>
                <Sidebar />
                <div className={classes.root}>
                    <Box p={8} style={{ backgroundColor: '#F4F6F8', paddingBottom: 60 }}>
                        <Toolbar />
                        <Grid container spacing={3}>
                            <Grid item xs={12} >
                                <Typography
                                    component={'span'}
                                    variant='h5'
                                    color='textPrimary'
                                    style={{ fontWeight: 600 }}
                                >
                                    <ArrowBackIcon size="36" title="Voltar" style={{ cursor: 'hand', color: '#E58934' }} onClick={() => {
                                        Navigate('/administracao')
                                    }} />   Cadastro de usuários
                                </Typography>

                                <hr />
                            </Grid>

                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <div className={classes.rootAc}>

                                    <Row>
                                        <Col>
                                            <Card className={classes.card} onClick={() => {
                                                const cliente = prompt("E-mail do Cliente")
                                            }}>
                                                <CardContent>
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 16 }} >
                                                        <Typography variant="h6" component="h2">
                                                            Perfil do Usuário
                                                        </Typography>

                                                    </div>
                                                </CardContent>

                                            </Card>
                                        </Col>
                                        <Col>
                                            <Card className={classes.card} onClick={() => {
                                                alert('Cadastro de Usuários')
                                            }}>
                                                <CardContent>
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 16 }} >
                                                        <Typography variant="h6" component="h2">
                                                            Cadastro de Usuários
                                                        </Typography>

                                                    </div>
                                                </CardContent>

                                            </Card>
                                        </Col>
                                        <Col>
                                            <Card className={classes.card} onClick={() => {
                                                alert('Cadastro de Usuários')
                                            }}>
                                                <CardContent>
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 16 }} >
                                                        <Typography variant="h6" component="h2">
                                                            Cadastro de Usuários
                                                        </Typography>

                                                    </div>
                                                </CardContent>

                                            </Card>
                                        </Col>
                                        <Col>
                                            <Card className={classes.card} onClick={() => {
                                                alert('Cadastro de Usuários')
                                            }}>
                                                <CardContent>
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 16 }} >
                                                        <Typography variant="h6" component="h2">
                                                            Cadastro de Usuários
                                                        </Typography>

                                                    </div>
                                                </CardContent>

                                            </Card>
                                        </Col>

                                    </Row>




                                </div>


                            </Grid>
                        </Grid>

                    </Box>




                </div>


                <Dialog
                    open={loading}
                    keepMounted
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title" style={{ fontSize: 16, textAlign: 'center' }} disableTypography={true}>{msgLoading}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">

                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <CircularProgress size={24} color={'primary'} />
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>






            </Box >

        </div >
    )
}

export default AdmUsuarios