import React, { useState, useContext, useEffect } from 'react';
import {
    makeStyles,
    AppBar,
    Toolbar,
    Button,
    List,
    Typography,
    ListItem,
    ListItemText,
    Box,
    Grid,
    CircularProgress,
    Tabs,
    Tab,
    Paper,
    Badge,
    Accordion,
    AccordionSummary,
    Tooltip
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import PropTypes from 'prop-types';
import InfoIcon from '@material-ui/icons/Info';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { GlobalContext } from "../contexts/global";
import Sidebar from '../components/sidebar'


import { withStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import { TiDelete } from 'react-icons/ti'

import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase'

import DataTable from 'react-data-table-component';

import { msgAviso } from "../components/sweet";

import BarraSuperior from '../components/barra'


const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        backgroundColor: theme.palette.background.dark,
        flexGrow: 1,
        padding: '6px'
    },
    fab: {
        position: 'absolute',
        zIndex: 1,
        bottom: theme.spacing(3),
        right: theme.spacing(3),
    },
    rootAc: {
        width: '100%'
    },
    drawer: {
        width: 240,
        flexShrink: 0,
    },
    drawerPaper: {
        width: 240,
        borderRight: 'none',
    },
    listItemText: {
        fontSize: 18,
        padding: 0,
        transition: '0.3s',
        '&:hover': {
            fontWeight: 'bold'
        }


    },
    listItem: {
        paddingTop: 4,
        paddingBottom: 4,
        height: 50,
        transition: '0.3s',
        '&:hover': {
            // transform: 'scale(1.02)',
            paddingLeft: 25,
        }
    },
    subheader: {
        textTransform: 'uppercase',
    },
    paper: {
        width: '80%',
        maxHeight: 435,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));

function Home({ darkMode, setDarkMode, dataColetas }) {

    const [openCadastros, setOpenCadastros] = React.useState(false);
    const [openGestao, setOpenGestao] = React.useState(false);
    const [openPlano, setOpenPlano] = React.useState(false);
    const [openTimes, setOpenTimes] = React.useState(false);
    const [openDetalheProduto, setOpenDetalheProduto] = React.useState(false);


    const [exibeDetColeta, setExibeDetColeta] = React.useState(false);
    const [exibePrincipal, setExibePrincipal] = React.useState(true);


    const [timeSelecionado, setTimeSelecionado] = React.useState('');

    const [urlImagem, setUrlImagem] = React.useState('');

    const [nomeBotaoTime, setNomeBotaoTime] = React.useState('Selecione o Time');

    const [dptoSelecionado, setDptoSelecionado] = React.useState('');
    const [vctoSelecionado, setVctoSelecionado] = React.useState('');
    const [linhaSelecionada, setLinhaSelecionada] = React.useState('');


    const [planoSelecionado, setPlanoSelecionado] = React.useState('');
    const [tabDetAtual, setTabDetAtual] = React.useState('');
    const [qtdeHistorico, setQtdeHistorico] = React.useState('');

    const [msgLoading, setMsgLoading] = React.useState('Solicitação em Andamento');


    const [loading, setLoading] = React.useState(false);

    const [tabSelecionada, setTabSelecionada] = React.useState(0);

    const aviso = withReactContent(Swal)

    const StyledBadge = withStyles((theme) => ({
        badge: {
            right: -15,
            top: 10,
            border: `2px solid ${theme.palette.background.paper}`,
            color: 'white',
        },
    }))(Badge);

    const mudarTab = (event, newValue) => {
        setTabSelecionada(newValue);
    };

    const [coletas, setColetas] = useState([]);
    const [historicoColetas, setHistoricoColetas] = useState([]);
    const [historicoTarefas, setHistoricoTarefas] = useState([]);
    const [detalheColetas, setDetalheColetas] = useState([]);
    const [dadosColeta, setDadosColeta] = useState([]);
    const [times, setTimes] = useState([]);

    const [integracao, SetIntegracao] = useState('');

    const [listaAcoes, setListaAcoes] = useState([]);

    const { conexao, usuario, qtdeTimes, registrarCodigoTime, registrarTimeAtual, timeAtual, codigoTime, salvarLogin, plano, salvarDadosPlano, registrarQtdeTimes, GeraLogServidor } = useContext(GlobalContext)

    const planosAcao = [
        {
            "identificacao": "1",
            "nome": "Notificar Comercial",
        },
        {
            "identificacao": "2",
            "nome": "Colocar em Oferta",
        },
        {
            "identificacao": "3",
            "nome": "Criar Alerta",
        },
    ];

    const abrirMenuCadastros = () => {
        setOpenGestao(false)
        setOpenPlano(false)
        setOpenCadastros(!openCadastros);
    };
    const abrirMenuGestao = () => {
        setOpenCadastros(false)
        setOpenPlano(false)
        setOpenGestao(!openGestao);
    };
    const abrirMenuPlano = () => {
        setOpenCadastros(false)
        setOpenGestao(false)
        setOpenPlano(!openPlano);
    };
    const abrirMenuTimes = () => {
        if (!openTimes) {
            setOpenTimes(true)
        }
    };
    const fecharMenuTimes = (nome, id) => {
        setOpenTimes(false)
        setNomeBotaoTime(nome)
        if (nome === '') {
            setNomeBotaoTime('Selecione o Time')
        }
        registrarCodigoTime(id)
        registrarTimeAtual(nome)
    };

    const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigate()

    const [loadingTable, setLoadingTable] = useState(false)

    useEffect(() => {

        console.log('Chegou no useEffect do Home')
        console.log('Quantidade Registrada de Times: ' + qtdeTimes)
        const conectado = auth.onAuthStateChanged(user => {
            if (user) {
            
                    salvarLogin(user.email)
                    // registraLogin(user.email, Device.deviceName)
                    console.log(user.email)
                    ValidaUsuario(user.email)
                    listarAcoes(user.email)
                    // CarregaColetas(user.email)

                    console.log('UseEffect!!!')
                    console.log(usuario)
                    setExibeDetColeta(false)
                    setExibePrincipal(true)
                    if (Number(qtdeTimes) > 0) {
                        ListarTimes()
                    } else {
                        console.log('Time Atual: ' + timeAtual)
                        // setNomeBotaoTime(timeAtual)
                        CarregaColetas(user.email)
                    }
              
            } else {
                console.log('Sai Daqui')
                navigate("/")
            }
        })

        return conectado;



    }, [])

    useEffect(() => {
        console.log('UseEffect da mudança de time!!!')
        setExibeDetColeta(false)
        setExibePrincipal(true)
        CarregaColetas(usuario.email)
    }, [timeAtual])

    function listarAcoes(emailInformado) {

        setLoading(true)
        setMsgLoading('Obtendo Ações')

        let url = conexao.endereco + '/ListaAcoes?usuario=' + emailInformado

        console.log('Request Enviado: ' + url)

        let request = new XMLHttpRequest()
        request.timeout = 10000
        request.open("GET", url, true)
        request.setRequestHeader("Content-Type", "application/json")
        request.setRequestHeader("Authorization", conexao.autenticacao)
        request.send()

        request.ontimeout = function () {
            msgAviso('Atenção', 'TimeOut na Conexão', 'warning', 'OK')
            setLoading(false)
        }

        request.onload = function () {

            setLoading(false)

            let codigoResposta = JSON.stringify(this.status)
            let textoResposta = JSON.parse(this.response)

            console.log('Ações Carregadas: ' + textoResposta)
            console.log(codigoResposta)

            if (codigoResposta != 200) {

                msgAviso('Atenção', 'Acesso Negado', 'error', 'ok');
                console.log(textoResposta)

            } else {

                setListaAcoes(textoResposta)
                console.log('Busca Finalizada com Sucesso!')

            }
        }
    }

    function SimpleDialog(props) {
        const classes = useStyles();
        const { onClose, selectedValue, open } = props;

        const handleClose = () => {
            onClose(selectedValue);
        };

        const handleListItemClick = (nome, id) => {
            onClose(nome, id);
        };

        return (
            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title" disableTypography={true}>Selecione o Time</DialogTitle>
                <List>
                    {times.map((time) => (
                        <ListItem button onClick={() => {
                            GeraLogServidor(usuario.email, 'DASH', 'VENCIMENTOS', 'Selecionou o Time: ' + time.nome)
                            handleListItemClick(time.nome, time.id)
                        }} key={time.id}>
                            <ListItemText primary={time.nome} />
                        </ListItem>
                    ))}

                </List>
            </Dialog>
        );
    }

    SimpleDialog.propTypes = {
        onClose: PropTypes.func.isRequired,
        open: PropTypes.bool.isRequired,
        selectedValue: PropTypes.string.isRequired,
    };


    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }



    function Sair() {
        auth
            .signOut()
            .then(() => {
                navigate("/")
                console.log('Deslogando da Conta')
            })
            .catch(error => alert(error.message))
    }

    function convertArrayOfObjectsToCSV(array) {
        let result;

        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(coletas[0]);

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;

                result += item[key];

                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    }

    // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
    function downloadCSV(array) {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;

        const filename = 'export.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    async function ListarTimes(origem) {

        console.log('Listando Times')

        setLoading(true)
        setMsgLoading('Listando Times')

        const url = conexao.endereco + "/ListarTimes?usuario=" + usuario.email

        console.log('Endereço Configurado: ' + url)

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("GET", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("Authorization", conexao.autenticacao)
        requisicao.send()

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onloadend = async function () {
            setLoading(false)
            console.log('Deu Certo!')
            console.log('RESPONSE TIME: ' + await this.response)
            let resposta = await JSON.parse(this.response)
            console.log('StatusCode: ' + requisicao.status)
            if (requisicao.status === 200) {

                console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ - ' + qtdeTimes)

                let respostaJson = JSON.parse(this.response)

                let idRetornado = respostaJson[0].id
                let nomeRetornado = respostaJson[0].nome

                console.log('Resposta do JSON: ' + JSON.stringify(respostaJson[0]))

                abrirMenuTimes()

                setTimes(resposta)

            } else {
                console.log('Nenhum Time Localizado para o Usuário ' + usuario.email)
                if (origem = 'btn') {
                    msgAviso('Atenção', 'Nenhum Time Localizado para o Usuário', 'info', 'OK')
                }
            }
            console.log(resposta);
        }

    }

    async function GetDetalheColeta(identificacao) {

        console.log('Obtendo Detalhes da Coleta')

        setLoading(true)
        setMsgLoading('Obtendo Detalhes da Coleta')

        let url = conexao.endereco + "/GetDetalheColeta?idColeta=" + identificacao

        if (Number(codigoTime) > 0) {
            url = url + '&time=' + codigoTime
        } else {
            url = url + '&time=0'
        }

        console.log('Endereço Configurado: ' + url)

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("GET", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("Authorization", conexao.autenticacao)
        requisicao.send()

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onloadend = async function () {
            setLoading(false)
            console.log('Deu Certo!')
            let resposta = await JSON.parse(this.response)
            console.log('StatusCode: ' + requisicao.status)
            if (requisicao.status === 200) {

                let respostaJson = JSON.parse(this.response)

                setTabSelecionada(0)
                setHistoricoColetas([])

                GetTarefas(identificacao)

                setUrlImagem('https://img.simplustec.com.br/product_images/' + respostaJson?.codbarras + '/1/' + respostaJson?.codbarras + '_1_1_600_72_RGB.png?pid=14136&cmid=3744&action=API&hash=e17c600fb462331d05b82f81b2f19e1e')

                console.log(respostaJson.usuario)

                if (respostaJson.qtde_historico > 0) {
                    GetHistoricoColeta(identificacao)
                }

                setDadosColeta(JSON.parse(this.response))

                setOpenDetalheProduto(true)

            } else {
                console.log('Nenhum Detalhe Localizado para o ID ' + linhaSelecionada)
            }
            console.log(resposta);
        }

    }

    async function GetHistoricoColeta(identificacao) {

        console.log('Obtendo Histórico da Coleta')

        // setLoading(true)
        // setMsgLoading('Obtendo Detalhes da Coleta')

        const url = conexao.endereco + "/HistoricoColeta?idColeta=" + identificacao

        console.log('Endereço Configurado: ' + url)

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("GET", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("Authorization", conexao.autenticacao)
        requisicao.send()

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
        }

        requisicao.onloadend = async function () {
            console.log('Deu Certo!')
            let resposta = await JSON.parse(this.response)
            console.log('StatusCode: ' + requisicao.status)
            if (requisicao.status === 200) {

                let respostaJson = JSON.parse(this.response)

                console.log(respostaJson.usuario)
                setHistoricoColetas(JSON.parse(this.response))

            } else {
                console.log('Nenhum Detalhe Localizado para o ID ' + linhaSelecionada)
            }
            console.log(resposta);
        }

    }

    async function GetTarefas(identificacao) {

        console.log('Obtendo Histórico de Tarefas da Coleta')

        // setLoading(true)
        // setMsgLoading('Obtendo Detalhes da Coleta')

        const url = conexao.endereco + "/ListaTarefas?idColeta=" + identificacao

        console.log('Endereço Configurado: ' + url)

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("GET", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("Authorization", conexao.autenticacao)
        requisicao.send()

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
        }

        requisicao.onloadend = async function () {
            console.log('Deu Certo!')
            let resposta = await JSON.parse(this.response)
            console.log('StatusCode: ' + requisicao.status)
            if (requisicao.status === 200) {

                let respostaJson = JSON.parse(this.response)

                console.log(respostaJson.usuario)
                setHistoricoTarefas(JSON.parse(this.response))

            } else {
                console.log('Nenhum Detalhe Localizado para o ID ' + linhaSelecionada)
            }
            console.log(resposta);
        }

    }

    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
            title: ''
        },
    }))(Tooltip);


    async function CarregaColetas(userLogado) {

        setLoading(true)
        setMsgLoading('Buscando Coletas')

        let url = conexao.endereco + '/IndiceColetas?usuario=' + userLogado + '&vencidos=N'

        if (timeAtual !== 'Selecione o Time' && Number(codigoTime) > 0) {
            url = url + '&time=' + codigoTime
        } else {
            url = url + '&time=0'
        }

        console.log('Request Enviado: ' + url)

        let request = new XMLHttpRequest()
        request.timeout = 10000
        request.open("GET", url, true)
        request.setRequestHeader("Content-Type", "application/json")
        request.setRequestHeader("Authorization", conexao.autenticacao)
        request.send()

        request.ontimeout = function () {
            msgAviso('Atenção', 'TimeOut na Conexão', 'warning', 'OK')
            setLoading(false)
        }

        request.onload = function () {

            console.log(this.response)

            setLoading(false)

            let codigoResposta = JSON.stringify(this.status)
            let textoResposta = JSON.parse(this.response)

            if (codigoResposta != 200) {

                msgAviso('Atenção', 'Acesso Negado', 'error', 'ok');
                console.log(textoResposta)

            } else {
                setColetas(textoResposta)
            }
        }
    };

    function CarregaDetalheColetas(dpto, data) {

        setExibeDetColeta(false)
        setExibePrincipal(false)
        setLoading(true)
        setMsgLoading('Listando Itens')

        setDptoSelecionado(dpto)
        setVctoSelecionado(data)

        let url = conexao.endereco + '/ListaColetas'

        // if (timeAtual !== 'Selecione o Time' && Number(codigoTime) > 0) {
        //     url = url + '&time=' + codigoTime
        // }

        let body = {
            "usuario": usuario.email,
            "data": data,
            "dpto": dpto,
            "time": codigoTime
        }

        console.log('Body Lista Coletas: ' + JSON.stringify(body))

        console.log('Request Enviado: ' + url)

        let request = new XMLHttpRequest()
        request.timeout = 10000
        request.open("POST", url, true)
        request.setRequestHeader("Content-Type", "application/json")
        request.setRequestHeader("Authorization", conexao.autenticacao)
        request.send(JSON.stringify(body))

        request.ontimeout = function () {
            msgAviso('Atenção', 'TimeOut na Conexão', 'warning', 'OK')
            setLoading(false)
        }

        request.onload = function () {

            setLoading(false)

            let codigoResposta = JSON.stringify(this.status)
            let textoResposta = JSON.parse(this.response)

            console.log('Resposta Antes do StatusCode: ' + textoResposta)
            console.log(codigoResposta)

            if (codigoResposta != 200) {

                msgAviso('Atenção', 'Acesso Negado', 'error', 'ok');
                console.log(textoResposta)

            } else {
                setDetalheColetas(textoResposta)
                console.log('Busca Finalizada com Sucesso!')
                setExibeDetColeta(true)
                setExibePrincipal(false)
            }
        }
    }

    async function AdicionaTarefa(idAcao, idColeta, Descricao) {

        setOpenDetalheProduto(false)

        setLoading(true)
        setMsgLoading('Gerando Tarefa')

        const urlAddTarefa = conexao.endereco + "/AdicionaTarefa"

        let body = {
            "descricao": Descricao,
            "idAcao": idAcao,
            "idColeta": idColeta,
        }

        console.log(body)

        let request = new XMLHttpRequest()
        request.timeout = 10000
        request.open("POST", urlAddTarefa, true)
        request.setRequestHeader("Content-type", "application/json")
        request.setRequestHeader("Authorization", conexao.autenticacao)
        request.send(JSON.stringify(body))

        request.ontimeout = function () {
            setLoading(false)
            alert('Erro no Envio da Requisição', 'TimeOut')
        }

        request.onload = function () {

            let resposta = JSON.stringify(this.status)
            let respostaJson = JSON.parse(this.response)

            setLoading(false)

            console.log('Resposta do Envio: ' + JSON.stringify(respostaJson.status))

            // msgAviso('Parabéns', 'Tarefa Gerada com Sucesso!', 'success', 'OK')

            CarregaDetalheColetas(dptoSelecionado, vctoSelecionado)

            // setOpenDetalheProduto(true)

        }

    }


    function ValidaUsuario(usuarioInformado) {

        console.log('Validando Licença do Usuário')

        const url = conexao.endereco + "/ValidaUsuario?usuario=" + usuarioInformado

        console.log('Endereço Configurado: ' + url)

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("GET", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("Authorization", conexao.autenticacao)
        requisicao.send()

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
        }

        requisicao.onerror = function () {
            salvarDadosPlano('GRATUITO', '', '')
            console.log('Deu Erro: ' + this.responseText)
        }

        requisicao.onloadend = function () {
            console.log('Deu Certo!')
            console.log('RESPONSE: ' + this.response)
            let resposta = JSON.parse(this.response)
            console.log('StatusCode: ' + requisicao.status)
            if (requisicao.status === 200) {

                salvarDadosPlano(resposta.plano, resposta.vencimento, resposta.status)

                // if (resposta.plano == 'GRATUITO') {
                //     navigate("/meu-plano")
                // }
                SetIntegracao(resposta.integracao)


                registrarQtdeTimes(resposta.times)


            } else {
                salvarDadosPlano('GRATUITO', '', '')
            }
            console.log(resposta);
        }

    }


    async function DeletaTarefa(idInformado) {

        setOpenDetalheProduto(false)

        aviso.fire({
            title: 'Atenção',
            text: 'Confirma Exclusão do Plano de Ação?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#E58934',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            zIndex: 0
        }).then((result) => {
            if (result.isConfirmed) {

                setLoading(true)
                setMsgLoading('Excluindo Plano de Ação')

                const urlDelDestinatario = conexao.endereco + "/ExcluirTarefa?idTarefa=" + idInformado

                let request = new XMLHttpRequest()
                request.timeout = 10000
                request.open("GET", urlDelDestinatario, true)
                request.setRequestHeader("Content-type", "application/json")
                request.setRequestHeader("Authorization", conexao.autenticacao)
                request.send(JSON.stringify())

                request.ontimeout = function () {
                    setLoading(false)
                    alert('Erro no Envio da Requisição', 'TimeOut')
                }

                request.onload = function () {

                    let resposta = JSON.stringify(this.status)
                    let respostaJson = JSON.parse(this.response)

                    setLoading(false)

                    console.log('Resposta do Envio: ' + JSON.stringify(respostaJson.status))

                    msgAviso('Plano de Ação Excluído com Sucesso!', '', 'success', 'OK')

                    CarregaDetalheColetas(dptoSelecionado, vctoSelecionado)

                    GetTarefas(dadosColeta.id)

                }

            }
        })


    }


    const colunasIndice = [
        {
            name: 'Departamento',
            selector: row => row.departamento,
            sortable: true,
        },
        {
            name: 'SKUs',
            selector: row => row.produtos,
            sortable: true,
        },
        {
            name: 'Volumes',
            selector: row => row.quantidades,
            sortable: true,
        },
        {
            name: 'Vencimento',
            selector: row => row.vencimento,
            sortable: true,
            conditionalCellStyles: [
                {
                    when: row => row.dias > 30,
                    style: {
                        backgroundColor: 'rgba(63, 195, 128, 0.9)',
                        color: 'white',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.dias > 7 && row.textodias <= 30,
                    style: {
                        backgroundColor: 'rgba(248, 148, 6, 0.9)',
                        color: 'white',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.dias <= 7,
                    style: {
                        backgroundColor: 'rgba(242, 38, 19, 0.9)',
                        color: 'white',
                        '&:hover': {
                            cursor: 'not-allowed',
                        },
                    },
                },
            ],
        },
    ];

    const paginationComponentOptions = {
        rowsPerPageText: 'Registros por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };


    const colunasDetalhe = [
        {
            name: 'Descrição',
            selector: row => row.descricao,
            sortable: true,
            wrap: true,
            minWidth: '400px',
        },
        {
            name: 'Código Barras',
            selector: row => row.codbarras,
            sortable: true,
            minWidth: '180px',
            center: true,
        },
        {
            name: 'Volume',
            selector: row => row.quantidade,
            sortable: true,
            center: true,
        },
        {
            name: 'Vencimento',
            selector: row => row.vencimento,
            sortable: true,
            center: true,
        },
        {
            name: 'Local',
            selector: row => row.local,
            sortable: true,
        },
        {
            name: 'Lote',
            selector: row => row.lote,
            sortable: true,
        },
        {
            name: 'Ações',
            selector: row => row.tarefas,
            sortable: true,
            minWidth: '20px',
            center: true,
        },
    ];

    const colunasDetalheRPInfo = [
        {
            name: 'Descrição',
            selector: row => row.descricao,
            sortable: true,
            wrap: true,
            minWidth: '400px',
        },
        {
            name: 'Código Barras',
            selector: row => row.codbarras,
            sortable: true,
            minWidth: '180px',
            center: true,
        },
        {
            name: 'Volume',
            selector: row => row.quantidade,
            sortable: true,
            center: true,
        },
        {
            name: 'Vencimento',
            selector: row => row.vencimento,
            sortable: true,
            center: true,
        },
        {
            name: 'Entidade',
            selector: row => row.local,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Transação',
            selector: row => row.lote,
            sortable: true,
        },
        {
            name: 'Ações',
            selector: row => row.tarefas,
            sortable: true,
            minWidth: '20px',
            center: true,
        },
    ];


    const colunasHistTarefas = [
        {
            name: 'Descrição',
            selector: row => row.descricao,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
        },
        {
            name: 'Inclusão',
            selector: row => row.inclusao,
            sortable: true,
        },
        {
            name: 'Conclusão',
            selector: row => row.conclusao,
            sortable: true,
        },
        {
            name: '',
            button: true,
            cell: row => (
                < a onClick={() => {
                    DeletaTarefa(row.id)
                }} >
                    <TiDelete size="24" color="#d9534f" title="Excluir Tarefa" />
                </a>
            ),
        },
    ];
    const colunasHistorico = [
        {
            name: 'Data',
            selector: row => row.data,
            // sortable: true,
        },
        {
            name: 'Quantidade Anterior',
            selector: row => row.quantidade_anterior,
            // sortable: true,
        },
        {
            name: 'Quantidade Atualizada',
            selector: row => row.quantidade_nova,
            // sortable: true,
        },
        {
            name: 'Usuário',
            selector: row => row.usuario,
            // sortable: true,
        },
    ];



    return (
        <div className={classes.root} >
            <BarraSuperior />
            <Box display='flex' >
                <Sidebar />
                <div className={classes.root} >

                    <Box p={8} style={{ backgroundColor: '#F4F6F8'}}>
                        <Toolbar />

                        <Grid container spacing={3} style={{ backgroundColor: '#F4F6F8'}}>

                            <Grid item xs={3} >
                                <Typography
                                    component={'span'}
                                    variant='h5'
                                    color='textPrimary'
                                    style={{ fontWeight: 600 }}
                                >
                                    Gestão de Validades
                                </Typography>
                                <br />
                                <Typography
                                    component={'span'}
                                    variant='h6'
                                    color='textPrimary'
                                    style={{ fontWeight: 400 }}
                                >
                                    Próximos Vencimentos
                                </Typography>
                            </Grid>
                            <Grid item xs={9} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>

                                {exibePrincipal &&
                                    <>
                                        <div style={{ width: 30, height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 20, color: '#E58934' }}>
                                            <LightTooltip placement="left-start" title={
                                                <React.Fragment>
                                                    <Typography color="inherit">Legenda de Cores - Coluna Vencimento</Typography>
                                                    <br />
                                                    <div style={{ backgroundColor: 'rgba(242, 38, 19, 0.9)', color: 'white', padding: 10, fontSize: 12 }}>
                                                        Até 7 Dias
                                                    </div>
                                                    <div style={{ backgroundColor: 'rgba(248, 148, 6, 0.9)', color: 'white', padding: 10, fontSize: 12 }}>
                                                        Entre 8 e 30 Dias
                                                    </div>
                                                    <div style={{ backgroundColor: 'rgba(63, 195, 128, 0.9)', color: 'white', padding: 10, fontSize: 12 }}>
                                                        Acima de 30 Dias
                                                    </div>

                                                </React.Fragment>
                                            }>
                                                <InfoIcon size="36" />
                                            </LightTooltip>
                                        </div>

                                        <Button
                                            variant="contained"
                                            style={{ backgroundColor: '#E58934', color: 'white' }}
                                            disabled={loading}
                                            onClick={() => {
                                                ListarTimes('btn')
                                            }}>
                                            {nomeBotaoTime}
                                        </Button>

                                    </>
                                }

                                {exibeDetColeta &&
                                    <Button
                                        variant="contained"
                                        style={{ backgroundColor: '#E58934', color: 'white' }}
                                        disabled={loading}
                                        onClick={() => {
                                            setExibeDetColeta(false)
                                            setExibePrincipal(true)
                                        }}>

                                        Voltar

                                    </Button>
                                }

                                {/* {planoSelecionado} */}

                                {usuario.email == 'contato@outbox.inf.br' &&
                                    <Button
                                        variant="contained"
                                        style={{ backgroundColor: '#E58934', color: 'white' }}
                                        disabled={loading}
                                        onClick={() => {
                                            const cliente = prompt("E-mail do Cliente")
                                            registrarCodigoTime(0)
                                            registrarTimeAtual('')
                                            CarregaColetas(cliente)
                                        }}>
                                        Selecionar Cliente
                                    </Button>
                                }

                                <SimpleDialog
                                    selectedValue={timeSelecionado}
                                    open={openTimes}
                                    onClose={fecharMenuTimes} />


                            </Grid>

                        </Grid>
                        <br />
                        {exibePrincipal &&

                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <DataTable
                                        columns={colunasIndice}
                                        data={coletas}
                                        onRowClicked={(row, index) => {
                                            GeraLogServidor(usuario.email, 'DASH', 'VENCIMENTOS', 'Clicou no Departamento: ' + row.departamento + ', Vencimento: ' + row.vencimento)
                                            CarregaDetalheColetas(row.departamento, row.vencimento)
                                        }}
                                        striped={true}
                                        highlightOnHover={true}
                                        responsive={true}
                                        pointerOnHover={true}
                                        noDataComponent={"Nenhum Registro Encontrado =/"}
                                        pagination={true}
                                        paginationComponentOptions={paginationComponentOptions}
                                    />
                                </Grid>
                            </Grid>

                        }


                        {exibeDetColeta &&
                            <>

                                <Typography
                                    component={'span'}
                                    variant='h6'
                                    color='textPrimary'
                                    style={{ fontWeight: 400 }}
                                >
                                    {dptoSelecionado}, {vctoSelecionado}

                                </Typography>
                                <br />
                                <br />
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        {integracao == 'RP Info' &&
                                            <DataTable
                                                columns={colunasDetalheRPInfo}
                                                data={detalheColetas}
                                                onRowClicked={(row, index) => {
                                                    GeraLogServidor(usuario.email, 'DASH', 'VENCIMENTOS', 'Selecionou a Coleta: ' + row.id + ', Produto: ' + row.descricao)
                                                    setLinhaSelecionada(row.id)
                                                    GetDetalheColeta(row.id)

                                                }}
                                                striped={true}
                                                highlightOnHover={true}
                                                responsive={true}
                                                pointerOnHover={true}
                                                noDataComponent={"Nenhum Registro Encontrado =/"}
                                                pagination={true}
                                                paginationComponentOptions={paginationComponentOptions}
                                            />
                                        }
                                        {integracao == 'N' &&
                                            <DataTable
                                                columns={colunasDetalhe}
                                                data={detalheColetas}
                                                onRowClicked={(row, index) => {
                                                    setLinhaSelecionada(row.id)
                                                    GetDetalheColeta(row.id)

                                                }}
                                                striped={true}
                                                highlightOnHover={true}
                                                responsive={true}
                                                pointerOnHover={true}
                                                noDataComponent={"Nenhum Registro Encontrado =/"}
                                                pagination={true}
                                                paginationComponentOptions={paginationComponentOptions}
                                            />
                                        }
                                    </Grid>
                                </Grid>
                            </>
                        }

                    </Box>

                </div>



            </Box>


            <Dialog
                open={loading}
                keepMounted
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title" style={{ fontSize: 16, textAlign: 'center' }} disableTypography={true}>{msgLoading}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">

                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress size={24} color={'primary'} />
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>


            <Dialog
                open={openDetalheProduto}
                keepMounted
                fullWidth
                maxWidth={'md'}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                onClose={() => {
                    setOpenDetalheProduto(false)
                }}
            >
                {/* <DialogTitle id="alert-dialog-slide-title" style={{ fontSize: 16, textAlign: 'center' }} disableTypography={false}>{'Detalhe do Produto Coletado'}</DialogTitle> */}
                <Paper style={{ height: 620 }}>
                    <DialogContentText id="alert-dialog-slide-description" >

                        <AppBar position="static" style={{ backgroundColor: '#fff', color: '#E58934' }}  >
                            {/* <Fab color="primary" aria-label="add" className={classes.fab} onClick={() => { setOpenDetalheProduto(false) }}>
                                <CloseIcon size={24} htmlColor='white' />
                            </Fab> */}

                            <Button
                                variant="outlined"
                                disabled={loading}
                                color="primary"
                                className={classes.fab}
                                onClick={() => {
                                    setOpenDetalheProduto(false)
                                }}>

                                fechar

                            </Button>

                            <Tabs value={tabSelecionada} onChange={mudarTab} aria-label="simple tabs example" centered>
                                <Tab label="Dados da Coleta" {...a11yProps(0)} />
                                <Tab label="Imagem" {...a11yProps(1)} />
                                <Tab label={<StyledBadge badgeContent={dadosColeta.qtde_historico} color="primary">Histórico</StyledBadge >}  {...a11yProps(2)} />
                                {listaAcoes &&
                                    <Tab label="Plano de Ação" {...a11yProps(3)} />
                                }

                            </Tabs>

                        </AppBar>

                        <TabPanel value={tabSelecionada} index={0}>
                            {/* <Typography variant='h6'>
                                {dadosColeta.descricao}
                            </Typography>
                            <Typography variant='body2'>
                                {dadosColeta.codbarras}
                            </Typography>
                            <br /> */}

                            <div className={classes.rootAc}>

                                <Accordion expanded={false}>
                                    <AccordionSummary>
                                        <Typography className={classes.heading}>Produto</Typography>
                                        <Typography className={classes.secondaryHeading}>{dadosColeta.descricao}</Typography>
                                    </AccordionSummary>
                                </Accordion>
                                <Accordion expanded={false}>
                                    <AccordionSummary>
                                        <Typography className={classes.heading}>Código de Barras</Typography>
                                        <Typography className={classes.secondaryHeading}>{dadosColeta.codbarras}</Typography>
                                    </AccordionSummary>
                                </Accordion>
                                <Accordion expanded={false}>
                                    <AccordionSummary>
                                        <Typography className={classes.heading}>Usuário Coleta</Typography>
                                        <Typography className={classes.secondaryHeading}>{dadosColeta.usuario}</Typography>
                                    </AccordionSummary>
                                </Accordion>
                                <Accordion expanded={false}>
                                    <AccordionSummary>
                                        <Typography className={classes.heading}>Data da Coleta</Typography>
                                        <Typography className={classes.secondaryHeading}>{dadosColeta.data}</Typography>
                                    </AccordionSummary>
                                </Accordion>
                                {dadosColeta.ultima_atualizacao &&
                                    <Accordion expanded={false}>
                                        <AccordionSummary>
                                            <Typography className={classes.heading}>Data Última Atualização</Typography>
                                            <Typography className={classes.secondaryHeading}>{dadosColeta.ultima_atualizacao}</Typography>
                                        </AccordionSummary>
                                    </Accordion>
                                }
                                <Accordion expanded={false}>
                                    <AccordionSummary>
                                        <Typography className={classes.heading}>Vencimento</Typography>
                                        <Typography className={classes.secondaryHeading}>{dadosColeta.vencimento}</Typography>
                                    </AccordionSummary>
                                </Accordion>
                                <Accordion expanded={false}>
                                    <AccordionSummary>
                                        <Typography className={classes.heading}>Quantidade Atual</Typography>
                                        <Typography className={classes.secondaryHeading}>{dadosColeta.quantidade}</Typography>
                                    </AccordionSummary>
                                </Accordion>
                                {dadosColeta.quantidade !== dadosColeta.quantidade_original &&
                                    <Accordion expanded={false}>
                                        <AccordionSummary>
                                            <Typography className={classes.heading}>Quantidade Original</Typography>
                                            <Typography className={classes.secondaryHeading}>{dadosColeta.quantidade_original}</Typography>
                                        </AccordionSummary>
                                    </Accordion>
                                }
                                {dadosColeta.lote &&
                                    <Accordion expanded={false}>
                                        <AccordionSummary>
                                            {integracao == 'RP Info' &&
                                                <Typography className={classes.heading}>Transação</Typography>
                                            }
                                            {integracao == 'N' &&
                                                <Typography className={classes.heading}>Lote</Typography>
                                            }
                                            <Typography className={classes.secondaryHeading}>{dadosColeta.lote}</Typography>
                                        </AccordionSummary>
                                    </Accordion>
                                }
                                {dadosColeta.local &&
                                    <Accordion expanded={false}>
                                        <AccordionSummary>
                                            {integracao == 'RP Info' &&
                                                <Typography className={classes.heading}>Entidade</Typography>
                                            }
                                            {integracao == 'N' &&
                                                <Typography className={classes.heading}>Local</Typography>
                                            }
                                            <Typography className={classes.secondaryHeading}>{dadosColeta.local}</Typography>
                                        </AccordionSummary>
                                    </Accordion>
                                }
                                {dadosColeta.time &&
                                    <Accordion expanded={false}>
                                        <AccordionSummary>
                                            <Typography className={classes.heading}>Time</Typography>
                                            <Typography className={classes.secondaryHeading}>{dadosColeta.time}</Typography>
                                        </AccordionSummary>
                                    </Accordion>
                                }
                            </div>

                        </TabPanel>
                        <TabPanel value={tabSelecionada} index={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ width: 500, height: 500, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img
                                    width={'auto'}
                                    height={450}
                                    src={urlImagem}
                                    loading="lazy"
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = "/images/not_found.png";
                                    }}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value={tabSelecionada} index={2}>
                            <DataTable
                                columns={colunasHistorico}
                                data={historicoColetas}
                                // onRowClicked={(row, index) => { CarregaDetalheColetas(row.departamento, row.vencimento) }}
                                striped={true}
                                highlightOnHover={true}
                                responsive={true}
                                pointerOnHover={true}
                                noDataComponent={"Nenhum Registro Encontrado =/"}
                            />
                        </TabPanel>
                        <TabPanel value={tabSelecionada} index={3}>

                            <List>
                                {listaAcoes.map((time) => (
                                    <ListItem button key={time.nome} className={classes.listItem} onClick={() => {
                                        AdicionaTarefa(time.id, dadosColeta.id, time.nome)
                                    }}>
                                        <ListItemText
                                            classes={{
                                                primary: classes.listItemText,
                                            }}
                                            primary={time.nome} />
                                    </ListItem>
                                ))}

                            </List>


                            <hr />
                            <DataTable
                                columns={colunasHistTarefas}
                                data={historicoTarefas}
                                // onRowClicked={(row, index) => {
                                //     setLinhaSelecionada(row.id)
                                //     GetDetalheColeta(row.id)

                                // }}
                                striped={true}
                                highlightOnHover={true}
                                responsive={true}
                                pointerOnHover={true}
                                noDataComponent={"Nenhum Registro Encontrado =/"}
                                pagination={true}
                                paginationPerPage={3}
                                paginationComponentOptions={paginationComponentOptions}
                            />
                        </TabPanel>

                    </DialogContentText>
                </Paper>
            </Dialog>


        </div>
    );

}

export default Home;
