import { useState, createContext } from 'react'

export const GlobalContext = createContext({})

function GlobalProvider({ children }) {

    const base64 = require('base-64');

    const [usuario, setUsuario] = useState({})
    const [plano, setPlano] = useState({})

    const [qtdeTimes, setQtdeTimes] = useState(0)
    const [timeAtual, setTimeAtual] = useState('')
    const [codigoTime, setCodigoTime] = useState(0)

    const [conexao, setConexao] = useState({
        endereco: 'https://outbox.inf.br:8082',
        autenticacao: 'Basic ' + base64.encode('OutBoxWS:0utb0x')
    })

    function salvarLogin(email) {
        setUsuario({
            email: email
        })
        console.log('Login Salvo no ContextApi - ' + email)
    }

    async function GeraLogServidor(usuarioInformado, origem, tipo, complemento) {

        console.log('Gerando Log de Acesso')

        const url = conexao.endereco + "/GeraLog"

        console.log('Endereço Configurado: ' + url)

        let requisicao = new XMLHttpRequest()

        let body = {
            "usuario": usuarioInformado,
            "origem": origem,
            "tipo": tipo,
            "complemento": complemento
        }

        requisicao.timeout = 8000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("Authorization", conexao.autenticacao)
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut na Geração de Log')
        }

        requisicao.onerror = function () {
            console.log('Deu Erro na Geração de Log: ' + this.responseText)
        }

        requisicao.onload = function () {
            console.log('Deu Certo a Geração de Log!')
            console.log('RESPONSE: ' + this.response)
            let resposta = JSON.parse(this.response)
            console.log('StatusCode: ' + requisicao.status)
        }

    }

    function salvarDadosPlano(plano, vencimento, status) {
        setPlano({
            plano: plano,
            vencimento: vencimento,
            status: status
        })
        console.log('Dados do Plano Salvos no ContextApi - ' + plano + ' - ' + vencimento)
    }

    function registrarQtdeTimes(qtde) {
        console.log('Registrando Quantidade de Times: ' + qtde)
        setQtdeTimes(qtde)
    }

    function registrarTimeAtual(time) {
        setTimeAtual(time)
    }
    function registrarCodigoTime(time) {
        setCodigoTime(time)
    }


    return (
        <GlobalContext.Provider value={{ conexao, usuario, salvarLogin, registrarQtdeTimes, salvarDadosPlano, qtdeTimes, registrarCodigoTime, registrarTimeAtual, timeAtual, codigoTime, plano, GeraLogServidor }}>
            {children}
        </GlobalContext.Provider>
    )
}

export default GlobalProvider;