import React, { useState, useContext, useEffect } from "react"
import {
    makeStyles,
    Box,
    Toolbar,
    Grid,
    Typography,
    Button,
    Dialog,
    DialogContentText,
    DialogTitle,
    DialogActions,
    TextField,
    TextareaAutosize,
    Checkbox,
    FormControlLabel,
    CircularProgress,
    DialogContent,
    InputLabel,
    Input,
    FormControl,
    Tooltip,
    AccordionSummary,
    AccordionDetails,
    Accordion,
    ListItem,
    ListItemText,
    Tab,
    Tabs
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from "moment";
import { useTheme } from '@material-ui/core/styles';
import { GlobalContext } from "../contexts/global";
import { msgAviso, msgConfirmacao } from "../components/sweet";
import { auth, db } from '../firebase'
import { useNavigate, useParams } from 'react-router-dom';
import { collection, getDocs, query, where, deleteDoc, doc, updateDoc, addDoc, orderBy } from "firebase/firestore";
import PropTypes from 'prop-types';
import RefreshIcon from '@material-ui/icons/Refresh';

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

import { getAuth, signInWithEmailAndPassword } from "firebase/auth";


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Sidebar from "../components/sidebar";
import BarraSuperior from "../components/barra";

import DataTable from 'react-data-table-component';
import { now } from "lodash";



const aviso = withReactContent(Swal)

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        backgroundColor: '#F4F6F8',
        flexGrow: 1,
        spacing: 4,
    },
    rootAc: {
        width: '100%',
    },
    listItemText: {
        // fontSize: 18,
        padding: 0,
        transition: '0.3s',
        '&:hover': {
            fontWeight: 'bold'
        }
    },
    linha: {
        width: '100%',
    },
    listItem: {
        paddingTop: 4,
        paddingBottom: 4,
        height: 50,
        transition: '0.3s',
        '&:hover': {
            paddingLeft: 25,
        }
    },
    margin: {
        margin: theme.spacing(1),
    },
    heading: {
        fontSize: 18,
        fontWeight: 'bold'
    },
    formulario: {
        display: 'flex',
        flexWrap: 'wrap',
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: '#E58934',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#E58934',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#E58934',
            },
            '&:hover fieldset': {
                borderColor: '#E58934',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#E58934',
            },
        },
    },
}));




function Administracao({ navigation, route }) {

    const classes = useStyles();
    const theme = useTheme();
    const Navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [msgLoading, setMsgLoading] = useState('')

    const [tabSelecionada, setTabSelecionada] = React.useState(0);

    const [tabLog, setTabLog] = useState(true)

    const mudarTab = (event, newValue) => {
        setTabSelecionada(newValue);
    };

    const [coletasPorUsuario, setColetasPorUsuario] = useState([])
    const [coletasPorTime, setColetasPorTime] = useState([])
    const [logsAcesso, setLogsAcesso] = useState([])

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const { id } = useParams();


    const { conexao, usuario, qtdeTimes, registrarCodigoTime, registrarTimeAtual, timeAtual, codigoTime, salvarLogin, salvarDadosPlano, registrarQtdeTimes } = useContext(GlobalContext)


    function Sair() {
        auth
            .signOut()
            .then(() => {
                Navigate("/")
                console.log('Deslogando da Conta')
                registrarCodigoTime(null)
                registrarTimeAtual(null)
            })
            .catch(error => alert(error.message))
    }


    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }


    useEffect(() => {
        console.log('Chegou no useEffect do Administracao')
        const conectado = auth.onAuthStateChanged(user => {
            if (user) {

                if (user.email == 'contato@outbox.inf.br') {
                    salvarLogin(user.email)
                    ValidaUsuario(user.email)
                    console.log(user.email)
                    CarregaStats()
                } else {
                    console.log('Sai Daqui')
                    Sair()
                    Navigate("/?origem=administracao")
                }

            } else {
                console.log('Sai Daqui')
                Navigate("/?origem=administracao")
            }
        })

    }, [])

    function ValidaUsuario(usuarioInformado) {

        console.log('Validando Licença do Usuário')

        const url = conexao.endereco + "/ValidaUsuario?usuario=" + usuarioInformado

        console.log('Endereço Configurado: ' + url)

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("GET", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("Authorization", conexao.autenticacao)
        requisicao.send()

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
        }

        requisicao.onerror = function () {
            salvarDadosPlano('GRATUITO', '', '')
            console.log('Deu Erro: ' + this.responseText)
        }

        requisicao.onloadend = function () {
            console.log('Deu Certo!')
            console.log('RESPONSE: ' + this.response)
            let resposta = JSON.parse(this.response)
            console.log('StatusCode: ' + requisicao.status)
            if (requisicao.status === 200) {

                salvarDadosPlano(resposta.plano, resposta.vencimento, resposta.status)

                // if (resposta.plano == 'GRATUITO') {
                //     navigate("/meu-plano")
                // }


                registrarQtdeTimes(resposta.times)

            } else {
                salvarDadosPlano('GRATUITO', '', '')
            }
            console.log(resposta);
        }

    }

    const colunasColetasUsuarios = [
        {
            name: 'Usuário',
            selector: row => row.usuario
        },
        {
            name: 'Coletas',
            selector: row => row.coletas
        },
        {
            name: 'Produtos',
            selector: row => row.produtos
        },
        {
            name: 'Departamentos',
            selector: row => row.departamentos
        },
        {
            name: 'Última Coleta',
            selector: row => row.ultima_coleta
        },
    ];

    const colunasColetasTimes = [
        // {
        //     name: 'Código Time',
        //     selector: row => row.id_time
        // },
        {
            name: 'Time',
            selector: row => row.time
        },
        {
            name: 'Proprietário',
            selector: row => row.proprietario
        },
        {
            name: 'Coletas',
            selector: row => row.coletas
        },
        {
            name: 'Produtos',
            selector: row => row.produtos
        },
        {
            name: 'Departamentos',
            selector: row => row.departamentos
        },
        {
            name: 'Última Coleta',
            selector: row => row.ultima_coleta
        },
    ];

    const colunasLogsAcesso = [
        // {
        //     name: 'ID',
        //     selector: row => row.id
        // },
        {
            name: 'Origem',
            selector: row => row.origem
        },
        {
            name: 'Tipo',
            selector: row => row.tipo
        },
        {
            name: 'Complemento',
            selector: row => row.complemento,
            wrap: true
        },
        {
            name: 'Data',
            selector: row => row.data
        },
        {
            name: 'Hora',
            selector: row => row.hora
        },
        // {
        //     name: 'Uusário',
        //     selector: row => row.usuario
        // },
    ];

    async function CarregaStats() {

        setLoading(true)
        setMsgLoading('Obtendo Dados dos Usuários')

        const urlColetasPorUsuario = conexao.endereco + "/Adm_Stats?stat=USERS"
        const urlColetasPorTime = conexao.endereco + "/Adm_Stats?stat=TIMES"
        const urlLogsAcesso = conexao.endereco + "/Adm_Stats?stat=LOG_ACESSO"

        let reqUser = new XMLHttpRequest()
        reqUser.timeout = 10000
        reqUser.open("GET", urlColetasPorUsuario, true)
        reqUser.setRequestHeader("Content-type", "application/json")
        reqUser.setRequestHeader("Authorization", conexao.autenticacao)
        reqUser.send()

        reqUser.ontimeout = function () {
            setLoading(false)
            alert('Erro ao Obter Dados', 'TimeOut')
        }

        reqUser.onloadend = async function () {

            let resposta = JSON.stringify(this.status)
            let respostaJson = await JSON.parse(this.response)

            console.log('Dados para o Relatório: ' + respostaJson)

            setColetasPorUsuario(respostaJson)
            setLoading(false)
        }

        let reqTimes = new XMLHttpRequest()
        reqTimes.timeout = 10000
        reqTimes.open("GET", urlColetasPorTime, true)
        reqTimes.setRequestHeader("Content-type", "application/json")
        reqTimes.setRequestHeader("Authorization", conexao.autenticacao)
        reqTimes.send()

        reqTimes.ontimeout = function () {
            setLoading(false)
            alert('Erro ao Obter Dados', 'TimeOut')
        }

        reqTimes.onloadend = async function () {

            let resposta = JSON.stringify(this.status)
            let respostaJson = await JSON.parse(this.response)

            console.log('Dados para o Relatório: ' + respostaJson)

            setColetasPorTime(respostaJson)
            setLoading(false)
        }

    };

    function CarregaLogs(UserInformado, dataIni, dataFin) {

        setLoading(true)
        setTabLog(false)
        setMsgLoading('Obtendo Logs do Usuários')

        const urlLogsAcesso = conexao.endereco + "/GetLog?usuario=" + UserInformado + "&datainicial=" + dataIni + "&datafinal=" + dataFin


        let reqLogAcesso = new XMLHttpRequest()
        reqLogAcesso.timeout = 10000
        reqLogAcesso.open("GET", urlLogsAcesso, true)
        reqLogAcesso.setRequestHeader("Content-type", "application/json")
        reqLogAcesso.setRequestHeader("Authorization", conexao.autenticacao)
        reqLogAcesso.send()

        reqLogAcesso.ontimeout = function () {
            setLoading(false)
            alert('Erro ao Obter Dados', 'TimeOut')
        }

        reqLogAcesso.onloadend = async function () {

            let resposta = JSON.stringify(this.status)
            let respostaJson = await JSON.parse(this.response)

            console.log('Dados para o Relatório: ' + respostaJson)

            setLogsAcesso(respostaJson)
            setLoading(false)
        }

    };


    return (
        <div className={classes.root}>
            <BarraSuperior />
            <Box display='flex'>
                <Sidebar />
                <div className={classes.root}>
                    <Box p={8} style={{ backgroundColor: '#F4F6F8', paddingBottom: 60 }}>
                        <Toolbar />
                        <Grid container spacing={3}>
                            <Grid item xs={12} >
                                <Typography
                                    component={'span'}
                                    variant='h5'
                                    color='textPrimary'
                                    style={{ fontWeight: 600 }}
                                >
                                    Administração  <RefreshIcon size="36" title="Excluir Tarefa" style={{ cursor: 'hand', color: 'green' }} onClick={() => {
                                        CarregaStats()
                                        setTabSelecionada(0)
                                    }} />
                                </Typography>

                                <hr />
                            </Grid>
                            {/* <Grid item xs={3} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                <Button
                                    variant="contained"
                                    // color="primary"
                                    style={{ color: 'white', backgroundColor: '#E58934' }}
                                    onClick={() => {
                                        CarregaUsuarios()
                                    }}>
                                    Selecionar Cliente
                                </Button>
                            </Grid> */}

                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <div className={classes.rootAc}>
                                    <Tabs value={tabSelecionada} onChange={mudarTab} aria-label="simple tabs example" centered>
                                        <Tab label="Cadastros por Usuário" {...a11yProps(0)} />
                                        <Tab label="Cadastros por Time" {...a11yProps(1)} />
                                        <Tab label="Logs" {...a11yProps(2)} disabled={tabLog} />
                                    </Tabs>

                                    <TabPanel value={tabSelecionada} index={0}>
                                        <DataTable
                                            columns={colunasColetasUsuarios}
                                            data={coletasPorUsuario}
                                            onRowClicked={(row, index) => {

                                                let hoje = new Date();

                                                var dataAtual = hoje.getDate() + '/' + (hoje.getMonth() + 1) + '/' + hoje.getFullYear()

                                                let dtIni = prompt("Data Inicial", dataAtual)
                                                let dtFin = prompt("Data Final", dataAtual)
                                                CarregaLogs(row.usuario, dtIni, dtFin)
                                                setTabLog(false)
                                                setTabSelecionada(2)
                                            }}
                                            striped={true}
                                            highlightOnHover={true}
                                            responsive={true}
                                            pointerOnHover={true}
                                            noDataComponent={"Nenhum Registro Encontrado =/"}
                                            pagination={true}
                                        />
                                    </TabPanel>

                                    <TabPanel value={tabSelecionada} index={1}>
                                        <DataTable
                                            columns={colunasColetasTimes}
                                            data={coletasPorTime}
                                            // onRowClicked={(row, index) => { CarregaDetalheColetas(row.departamento, row.vencimento) }}
                                            striped={true}
                                            highlightOnHover={true}
                                            responsive={true}
                                            pointerOnHover={true}
                                            noDataComponent={"Nenhum Registro Encontrado =/"}
                                            pagination={true}
                                        />
                                    </TabPanel>

                                    <TabPanel value={tabSelecionada} index={2}>
                                        {!tabLog &&
                                            <DataTable
                                                columns={colunasLogsAcesso}
                                                data={logsAcesso}
                                                // onRowClicked={(row, index) => { CarregaDetalheColetas(row.departamento, row.vencimento) }}
                                                striped={true}
                                                highlightOnHover={true}
                                                responsive={true}
                                                pointerOnHover={true}
                                                noDataComponent={"Nenhum Registro Encontrado =/"}
                                                pagination={true}
                                            />
                                        }
                                    </TabPanel>
                                </div>


                            </Grid>
                        </Grid>

                    </Box>




                </div>


                <Dialog
                    open={loading}
                    keepMounted
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title" style={{ fontSize: 16, textAlign: 'center' }} disableTypography={true}>{msgLoading}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">

                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <CircularProgress size={24} color={'primary'} />
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>






            </Box >

        </div >
    )
}

export default Administracao