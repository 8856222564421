import React, { useState, useContext, useEffect } from "react"
import {
    makeStyles,
    Box,
    Toolbar,
    Grid,
    Typography,
    Button,
    Dialog,
    DialogContentText,
    DialogTitle,
    DialogActions,
    TextField,
    Checkbox,
    FormControlLabel,
    CircularProgress,
    DialogContent,
    Card,
    CardActions,
    CardContent
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { GlobalContext } from "../contexts/global";
import { msgAviso } from "../components/sweet";
import { auth, db } from '../firebase'
import { useNavigate } from 'react-router-dom';

import Chart from "react-apexcharts";

import Sidebar from "../components/sidebar";
import BarraSuperior from "../components/barra";

import DataTable from 'react-data-table-component';


const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        backgroundColor: '#F4F6F8',
        flexGrow: 1,
        spacing: 4,
    },
    card: {
        minWidth: '40%',
        margin: 20,
        transition: '0.3s',
        '&:hover': {
            transform: 'scale(1.15)',
        }
    },
    cardGrafico: {
        minWidth: '100%',
        height: 476,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: '0.3s',
        '&:hover': {
            transform: 'scale(1.10)',
        }
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    number: {
        marginTop: 25,
        fontSize: 72,
    },
    number7: {
        marginTop: 25,
        fontSize: 72,
        color: 'rgba(242, 38, 19, 0.9)'
    },
    number730: {
        marginTop: 25,
        fontSize: 72,
        color: 'rgba(248, 148, 6, 0.9)'
    },
    number30: {
        marginTop: 25,
        fontSize: 72,
        color: 'rgba(63, 195, 128, 0.9)'
    },
    pos: {
        marginTop: 12,
        textAlign: 'center',
        // backgroundColor: 'red'
    },
    grafico: {
        padding: 120,
        // transform: 'rotate(90deg)'
    },
    formulario: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: '#E58934',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#E58934',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#E58934',
            },
            '&:hover fieldset': {
                borderColor: '#E58934',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#E58934',
            },
        },

    },


}));

function Charts() {


    useEffect(() => {
        console.log('Chegou no useEffect do Home')
        console.log('Quantidade Registrada de Times: ' + qtdeTimes)
        const conectado = auth.onAuthStateChanged(user => {
            if (user) {

                salvarLogin(user.email)
                // registraLogin(user.email, Device.deviceName)
                console.log(user.email)
                ValidaUsuario(user.email)
                // CarregaColetas(user.email)

                GetGrafico(user.email)

                console.log('UseEffect!!!')
                console.log(usuario)


            } else {
                console.log('Sai Daqui')
                navigate("/")
            }
        })

        return conectado;



    }, [])

    function ValidaUsuario(usuarioInformado) {

        console.log('Validando Licença do Usuário')

        const url = conexao.endereco + "/ValidaUsuario?usuario=" + usuarioInformado

        console.log('Endereço Configurado: ' + url)

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("GET", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("Authorization", conexao.autenticacao)
        requisicao.send()

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
        }

        requisicao.onerror = function () {
            salvarDadosPlano('GRATUITO', '', '')
            console.log('Deu Erro: ' + this.responseText)
        }

        requisicao.onloadend = function () {
            console.log('Deu Certo!')
            console.log('RESPONSE: ' + this.response)
            let resposta = JSON.parse(this.response)
            console.log('StatusCode: ' + requisicao.status)
            if (requisicao.status === 200) {

                salvarDadosPlano(resposta.plano, resposta.vencimento, resposta.status)

                // if (resposta.plano == 'GRATUITO') {
                //     navigate("/meu-plano")
                // }

                registrarQtdeTimes(resposta.times)


            } else {
                salvarDadosPlano('GRATUITO', '', '')
            }
            console.log(resposta);
        }

    }



    const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigate()

    const [dadosGrafico, setDadosGrafico] = useState(null)

    const [chart7Dash, setChart7Dash] = useState('')
    const [chart730Dash, setChart730Dash] = useState('')
    const [chart30Dash, setChart30Dash] = useState('')
    const [chartTotalDash, setChartTotalDash] = useState('')

    const [chart7, setChart7] = useState('')
    const [chart730, setChart730] = useState('')
    const [chart30, setChart30] = useState('')
    const [chartTotal, setChartTotal] = useState('')

    const [openCadastroDest, setOpenCadastroDest] = useState(false)

    const [loading, setLoading] = useState(false)
    const [msgLoading, setMsgLoading] = useState('')


    const [nome, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [grupo, setGrupo] = useState('')
    const [telefone, setTelefone] = useState('')
    const [sms, setSms] = useState(false)
    const [whatsapp, setWhatsapp] = useState(false)

    const bull = <span className={classes.bullet}>•</span>;

    const { conexao, usuario, qtdeTimes, registrarCodigoTime, registrarTimeAtual, timeAtual, codigoTime, salvarLogin, plano, salvarDadosPlano, registrarQtdeTimes } = useContext(GlobalContext)


    const colunasDestinatarios = [
        {
            name: 'Nome',
            selector: row => row.nome,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Grupo',
            selector: row => row.grupo,
            sortable: true,
        },
        {
            name: 'SMS',
            selector: row => row.sms,
            sortable: true,
        },
        {
            name: 'WhatsApp',
            selector: row => row.whatsapp,
            sortable: true,
        },
    ];

    async function GetGrafico(userLogado) {

        setLoading(true)
        setMsgLoading('Obtendo Estatísticas')

        const urlGrafico = conexao.endereco + "/GetGraficoHome?usuario=" + userLogado

        console.log('Buscando Gráfico: ' + urlGrafico)

        let request = new XMLHttpRequest()
        request.timeout = 10000
        request.open("GET", urlGrafico, true)
        request.setRequestHeader("Content-type", "application/json")
        request.setRequestHeader("Authorization", conexao.autenticacao)
        request.send()

        request.ontimeout = function () {
            setLoading(false)
            alert('Erro no Envio do Cadastro', 'TimeOut')
        }

        request.onloadend = async function () {

            let resposta = JSON.stringify(this.status)
            let respostaJson = await JSON.parse(this.response)

            setDadosGrafico(respostaJson)

            console.log('Gráficos: ' + respostaJson)



            if (!respostaJson.find(pzTotal => pzTotal.prazo === 'total')?.volume) {
                setChartTotalDash(0)
                setChartTotal(0)
            } else {
                setChartTotalDash(Number(await respostaJson.find(pzTotal => pzTotal.prazo === 'total')?.volume).toLocaleString('pt-BR', {}))
                setChartTotal(await respostaJson.find(pzTotal => pzTotal.prazo === 'total')?.volume)
            }

            if (!respostaJson.find(pz7 => pz7.prazo === 'ATÉ 7 DIAS')?.volume) {
                setChart7Dash(0)
                setChart7(0)
            } else {
                setChart7Dash(Number(await respostaJson.find(pz7 => pz7.prazo === 'ATÉ 7 DIAS')?.volume).toLocaleString('pt-BR', {}))
                setChart7(await respostaJson.find(pz7 => pz7.prazo === 'ATÉ 7 DIAS')?.volume)
            }

            if (!respostaJson.find(pz730 => pz730.prazo === 'ENTRE 7 E 30 DIAS')?.volume) {
                setChart730Dash(0)
                setChart730(0)
            } else {
                setChart730Dash(Number(await respostaJson.find(pz730 => pz730.prazo === 'ENTRE 7 E 30 DIAS')?.volume).toLocaleString('pt-BR', {}))
                setChart730(await respostaJson.find(pz730 => pz730.prazo === 'ENTRE 7 E 30 DIAS')?.volume)
            }

            if (!respostaJson.find(pz30 => pz30.prazo === 'ACIMA DE 30 DIAS')?.volume) {
                setChart30Dash(0)
                setChart30(0)
            } else {
                setChart30Dash(Number(await respostaJson.find(pz30 => pz30.prazo === 'ACIMA DE 30 DIAS')?.volume).toLocaleString('pt-BR', {}))
                setChart30(await respostaJson.find(pz30 => pz30.prazo === 'ACIMA DE 30 DIAS')?.volume)
            }

            // setChart7((respostaJson.find(prazo => prazo.prazo === 'ATÉ 7 DIAS')))
            // setChart730((respostaJson.find(prazo => prazo.prazo === 'ENTRE 7 E 30 DIAS')))
            // setChart30((respostaJson.find(prazo => prazo.prazo === 'ACIMA DE 30 DIAS')))

            setLoading(false)

        }

    }


    return (
        <div className={classes.root}>
            <BarraSuperior />
            <Box display='flex'>
                <Sidebar />
                <div className={classes.root} >
                    <Box p={8} xs={1}>
                        <Toolbar />
                        {!loading && chartTotal !== '0' &&

                            <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignItems: 'center' }} >

                                <Grid sm={12} xs={12} md={12} lg={6} style={{ justifyContent: 'center', alignItems: 'center', }}>

                                    <Card className={classes.cardGrafico}>

                                        <Chart
                                            type="pie"
                                            width={500}
                                            height={600}
                                            series={[Number(chartTotal), Number(chart7), Number(chart730), Number(chart30)]}
                                            options={{
                                                labels: ['Pendentes de Vencimento', 'Até 7 Dias', 'Entre 7 e 30 Dias', 'Acima de 30 Dias'],
                                                colors: ['#215497', '#F33B2A', '#F99E1E', '#52C98C']
                                            }}
                                        />
                                    </Card>

                                </Grid>

                                <Grid container sm={12} xs={12} md={12} lg={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>

                                    <Card className={classes.card}>
                                        <CardContent>
                                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                Pendentes de Vencimento
                                            </Typography>
                                            <Typography className={classes.number} variant="h5" component="h2">
                                                {chartTotalDash}
                                            </Typography>
                                            <Typography className={classes.pos} color="textSecondary">
                                                Produto(s)
                                            </Typography>
                                        </CardContent>

                                    </Card>

                                    <Card className={classes.card}>
                                        <CardContent>
                                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                Até 7 Dias
                                            </Typography>
                                            <Typography className={classes.number7} variant="h5" component="h2">
                                                {chart7Dash}
                                            </Typography>
                                            <Typography className={classes.pos} color="textSecondary">
                                                Produto(s)
                                            </Typography>
                                        </CardContent>

                                    </Card>
                                    <Card className={classes.card}>
                                        <CardContent>
                                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                Entre 7 e 30 Dias
                                            </Typography>
                                            <Typography className={classes.number730} variant="h5" component="h2">
                                                {chart730Dash}
                                            </Typography>
                                            <Typography className={classes.pos} color="textSecondary">
                                                Produto(s)
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                    <Card className={classes.card}>
                                        <CardContent>
                                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                Acima de 30 Dias
                                            </Typography>
                                            <Typography className={classes.number30} variant="h5" component="h2">
                                                {chart30Dash}
                                            </Typography>
                                            <Typography className={classes.pos} color="textSecondary">
                                                Produto(s)
                                            </Typography>
                                        </CardContent>

                                    </Card>
                                </Grid>

                            </Grid>

                        }

                        {!loading && chartTotal == '0' &&
                            <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignItems: 'center' }} >
                                <Grid sm={12} xs={12} md={12} lg={6} style={{ justifyContent: 'center', alignItems: 'center' }}>
                                    <Card className={classes.cardGrafico}>
                                        {/* <p> Gráfico Indisponível com os Dados Atuais </p> */}
                                    </Card>
                                </Grid>

                                <Grid container sm={12} xs={12} md={12} lg={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>

                                    <Card className={classes.card}>
                                        <CardContent>
                                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                Pendentes de Vencimento
                                            </Typography>
                                            <Typography className={classes.number} variant="h5" component="h2">
                                                0
                                            </Typography>
                                            <Typography className={classes.pos} color="textSecondary">
                                                Produto(s)
                                            </Typography>
                                        </CardContent>

                                    </Card>

                                    <Card className={classes.card}>
                                        <CardContent>
                                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                Até 7 Dias
                                            </Typography>
                                            <Typography className={classes.number7} variant="h5" component="h2">
                                                0
                                            </Typography>
                                            <Typography className={classes.pos} color="textSecondary">
                                                Produto(s)
                                            </Typography>
                                        </CardContent>

                                    </Card>
                                    <Card className={classes.card}>
                                        <CardContent>
                                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                Entre 7 e 30 Dias
                                            </Typography>
                                            <Typography className={classes.number730} variant="h5" component="h2">
                                                0
                                            </Typography>
                                            <Typography className={classes.pos} color="textSecondary">
                                                Produto(s)
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                    <Card className={classes.card}>
                                        <CardContent>
                                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                Acima de 30 Dias
                                            </Typography>
                                            <Typography className={classes.number30} variant="h5" component="h2">
                                                0
                                            </Typography>
                                            <Typography className={classes.pos} color="textSecondary">
                                                Produto(s)
                                            </Typography>
                                        </CardContent>

                                    </Card>
                                </Grid>

                            </Grid>
                        }


                        {usuario.email == 'contato@outbox.inf.br' &&
                            <Button
                                variant="contained"
                                style={{ backgroundColor: '#E58934', color: 'white' }}
                                disabled={loading}
                                onClick={() => {
                                    const cliente = prompt("E-mail do Cliente")
                                    GetGrafico(cliente)
                                }}>
                                Selecionar Cliente
                            </Button>
                        }
                        {/* <Grid container sx={12} style={{ justifyContent: 'center' }}>
                            <PieChart
                                data={[
                                    { title: 'One', value: 10, color: '#E38627' },
                                    { title: 'Two', value: 15, color: '#C13C37' },
                                    { title: 'Three', value: 20, color: '#6A2135' },
                                ]}
                            />;
                        </Grid> */}


                    </Box>


                </div>


                <Dialog
                    open={loading}
                    keepMounted
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title" style={{ fontSize: 16, textAlign: 'center' }} disableTypography={true}>{msgLoading}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">

                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <CircularProgress size={24} color={'primary'} />
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>






            </Box >

        </div >
    )
}

export default Charts