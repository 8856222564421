import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { ThemeProvider, createTheme, makeStyles } from '@material-ui/core';
// import { Button, Container, Modal, Spinner } from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { collection, getDocs, addDoc, doc, deleteDoc, updateDoc } from "firebase/firestore";
import { auth, db } from '../firebase'

import { msgAviso } from "../components/sweet";

import { GlobalContext } from "../contexts/global";

import Navegacao from "../components/navbar";

import Home from "./home";
import Login from "./login";

const useStyles = makeStyles({
    root: {},
});


function Dashboard() {

    const navigate = useNavigate();

    const { conexao, usuario, salvarLogin, qtdeTimes, plano, salvarDadosPlano, registrarQtdeTimes } = useContext(GlobalContext)

    const [detalhe, setDetalhe] = useState(false);
    const [dataSelecionada, setDataSelecionada] = useState('');
    const [dptoSelecionado, setDptoSelecionado] = useState('');

    const [detalheColetas, setDetalheColetas] = useState([]);

    const [loadingTable, setLoadingTable] = useState(false);
    const [loadingColetas, setLoadingColetas] = useState(false);

    const [darkMode, setDarkMode] = useState(false);
    const [dataCol, setDataCol] = useState(false);

    const theme = createTheme({
        spacing: 4,
        palette: {
            type: darkMode ? 'dark' : 'light',
            primary: {
                main: '#E58934',
                dark: '#E58934',
                light: '#E58934',
            },
            secondary: {
                main: '#E58934',
                dark: '#E58934',
                light: '#E58934',
            },
            background: {
                default: darkMode ? '#232323' : '#FFF',
                dark: darkMode ? '#181818' : '#f4f6f8',
                paper: darkMode ? '#232323' : '#FFF',
            },
        },
    });

    const classes = useStyles();

    useEffect(() => {
        console.log('Chegou no useEffect do Dashboard')

        const conectado = auth.onAuthStateChanged(user => {
            if (user) {

                salvarLogin(user.email)
                // registraLogin(user.email, Device.deviceName)
                console.log(user.email)
                // CarregaColetas(user.email)

            } else {
                console.log('Sai Daqui 222')
                navigate("/")
            }
        })

        return conectado;
    }, [])

    async function CarregaColetas(userLogado) {

        let url = conexao.endereco + '/IndiceColetas?usuario=' + userLogado + '&vencidos=N'

        console.log('Request Enviado: ' + url)

        let request = new XMLHttpRequest()
        request.timeout = 10000
        request.open("GET", url, true)
        request.setRequestHeader("Content-Type", "application/json")
        request.setRequestHeader("Authorization", conexao.autenticacao)
        request.send()

        request.ontimeout = function () {
            msgAviso('Atenção', 'TimeOut na Conexão', 'warning', 'OK')
        }

        request.onload = function () {

            console.log(this.response)

            let codigoResposta = JSON.stringify(this.status)
            let textoResposta = JSON.parse(this.response)

            if (codigoResposta != 200) {

                msgAviso('Atenção', 'Acesso Negado', 'error', 'ok');
                console.log(textoResposta)

            } else {
                setDataCol(textoResposta)
            }
        }
    };


    return (

        <ThemeProvider theme={theme}>
            <Home darkMode={darkMode} setDarkMode={setDarkMode} dataColetas={dataCol} />
        </ThemeProvider>

    )
}

export default Dashboard;
