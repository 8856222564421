import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useContext, createContext } from 'react'

export const firebaseApp = initializeApp({
    apiKey: "AIzaSyDXs3luKftZ4tZntbZ7XQEwictVGIlntz4",
    authDomain: "gestao-validades.firebaseapp.com",
    projectId: "gestao-validades",
    storageBucket: "gestao-validades.appspot.com",
    messagingSenderId: "841117325378",
    appId: "1:841117325378:web:aeb630eea3b864adea35db"
})

export const db = getFirestore();
export const auth = getAuth()
export const storage = getStorage();

export const AuthContext = createContext()

export const useAuthState = () => {
    const auth = useContext(AuthContext)
    return { ...auth, isAuthenticated: auth != null }
}
