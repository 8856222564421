import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { firebaseApp, auth, db } from '../firebase';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { collection, getDocs, getDoc, doc, query, where } from "firebase/firestore";
import { BiBarcodeReader } from 'react-icons/bi'

import { msgAviso } from '../components/sweet'
import { GlobalContext } from '../contexts/global';

import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { CircularProgress } from '@material-ui/core';


function Copyright() {
    return (
        <Typography component={'span'} variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://gestaodevalidades.com.br/">
                Gestão de Validades
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: 'url(images/bglogin.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        padding: theme.spacing(8, 4),
        // backgroundColor: 'red',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        '& label.Mui-focused': {
            color: '#E58934',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#E58934',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#E58934',
            },
            '&:hover fieldset': {
                borderColor: '#E58934',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#E58934',
            },
        },
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: '#E58934',
        color: 'white',
        '&:hover': {
            background: "#D7751C",
        },
    },
    buttonProgress: {
        color: '#D7751C',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    textField: {
        borderColor: '#D7751C',
        borderBottomColor: '#D7751C',
    },
    label: {
        backgroundColor: "white"
    }
}));

function Login(props) {

    const navigate = useNavigate();
    const classes = useStyles();

    const [editUsuario, setEditUsuario] = useState('')
    const [editSenha, setEditSenha] = useState('')

    const [dadosUsuario, setDadosUsuario] = useState([])

    const [loading, setLoading] = useState(false)
    const [msgLoading, setMsgLoading] = useState('')

    const { salvarLogin, conexao, salvarDadosPlano, registrarQtdeTimes, GeraLogServidor } = useContext(GlobalContext)

    const aviso = withReactContent(Swal)

    const waitFor = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    )

    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    function ValidaUsuario(usuarioInformado) {

        setLoading(true)

        console.log('Validando Licença do Usuário')

        const url = conexao.endereco + "/ValidaUsuario?usuario=" + usuarioInformado

        console.log('Endereço Configurado: ' + url)

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("GET", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("Authorization", conexao.autenticacao)
        requisicao.send()

        requisicao.ontimeout = function () {
            setLoading(false)
            console.log('Deu TimeOut')
        }

        requisicao.onerror = function () {
            setLoading(false)
            salvarDadosPlano('GRATUITO', '', '')
            console.log('Deu Erro: ' + this.responseText)
        }

        requisicao.onloadend = function () {
            setLoading(false)
            console.log('Deu Certo!')
            console.log('RESPONSE: ' + this.response)
            let resposta = JSON.parse(this.response)
            console.log('StatusCode: ' + requisicao.status)
            if (requisicao.status === 200) {

                salvarDadosPlano(resposta.plano, resposta.vencimento, resposta.status)

                // if (resposta.plano == 'GRATUITO') {
                //     navigate("/meu-plano")
                // }

                registrarQtdeTimes(resposta.times)


            } else {
                salvarDadosPlano('GRATUITO', '', '')
            }
            console.log(resposta);
        }

    }


    async function Acessar() {
        setLoading(true)
        setMsgLoading('Validando Acesso')
        // await waitFor(5000)

        console.log('Logando...')
        signInWithEmailAndPassword(auth, editUsuario, editSenha)
            .then((userCredential) => {
                setLoading(false)
                const user = userCredential.user;
                console.log('Logado com Sucesso!')
                console.log('Email: ' + user.email)
                salvarLogin(user.email)
                ValidaUsuario(user.email)
                GeraLogServidor(user.email, 'DASH', 'ACESSO', 'Acesso Realizado pelo Dashboard')
                let origemURL = usarquery.get("origem");
                if (origemURL) {
                    navigate("/" + origemURL)
                } else {
                    navigate("/dashboard")
                }

            })
            .catch((error) => {
                setLoading(false)
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log('Erro: ' + errorCode + ' - ' + errorMessage)
                msgAviso('Atenção', 'Acesso Negado', 'error', 'ok');
            })

    }

    async function EsqueciMinhaSenha() {

        // await waitFor(5000)

        aviso.fire({
            title: 'Esqueceu sua Senha?',
            text: 'Vamos Enviar um E-mail para Resetar a Senha',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#E58934',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            zIndex: 0
        }).then((result) => {
            if (result.isConfirmed) {

                if (!editUsuario) {
                    msgAviso("Atenção", "Digite seu E-mail antes de Resetar a Senha", "info", "OK")
                } else {

                    setLoading(true)
                    setMsgLoading('Processando')

                    console.log('Processando...')
                    sendPasswordResetEmail(auth, editUsuario)
                        .then(() => {
                            setLoading(false)
                            msgAviso('E-mail Enviado', 'Enviamos um Link para Redefinir sua Senha, Verifique seu E-mail', 'info', 'OK')

                        })
                        .catch((error) => {
                            setLoading(false)
                            const errorCode = error.code;
                            const errorMessage = error.message;
                            console.log('Erro: ' + errorCode + ' - ' + errorMessage)
                            msgAviso(errorCode, errorMessage, 'error', 'ok');
                        })
                }

            }
        })

    }

    async function buscaDadosUsuario(emailUsuario) {
        const q = query(collection(db, "dados_usuarios"), where("email", "==", emailUsuario));
        const documentos = await getDocs(q);

        documentos.forEach((doc) => {
            setDadosUsuario(dadosUsuario => dadosUsuario.concat({ ...doc.data(), id: doc.id }))
        })
        console.log(dadosUsuario)
    };


    useEffect(() => {
        setLoading(true)
        setMsgLoading('Validando Usuário Já Logado')
        console.log('Chegou no useEffect do Login')
        const conectado = auth.onAuthStateChanged(user => {
            if (user) {

                setLoading(false)
                console.log(user.email)
                buscaDadosUsuario(user.email)
                GeraLogServidor(user.email, 'DASH', 'ACESSO', 'Acesso Realizado pelo Dashboard')
                let origemURL = usarquery.get("origem");
                if (origemURL) {
                    navigate("/" + origemURL)

                }
                setLoading(false)
            }
            setLoading(false)
        })

        return conectado;
    }, [])

    let usarquery = useQuery();

    return (


        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={8} className={classes.image} />
            <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <img
                        src={'/images/login.png'}
                        alt='logo'
                        className={classes.logo}
                    />
                    <form className={classes.form} noValidate>
                        <TextField
                            InputLabelProps={{
                                classes: {
                                    root: classes.label
                                }
                            }}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.textField,
                                }
                            }}
                            variant="outlined"
                            margin="normal"
                            required
                            disabled={loading}
                            fullWidth
                            id="email"
                            label="Endereço de E-mail"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={editUsuario}
                            onChange={(e) => { setEditUsuario(e.target.value) }}

                        />
                        <TextField
                            InputLabelProps={{
                                classes: {
                                    root: classes.label
                                }
                            }}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.textField
                                }
                            }}
                            variant="outlined"
                            margin="normal"
                            required
                            disabled={loading}
                            fullWidth
                            name="password"
                            label="Senha"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={editSenha}
                            onChange={(e) => { setEditSenha(e.target.value) }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    Acessar()
                                }
                            }}
                        />
                        {/* <FormControlLabel
                            control={<Checkbox value="remember" />}
                            label="Lembrar"
                            style={{ color: '#E58934' }}
                        /> */}
                        <Button
                            fullWidth
                            variant="contained"
                            className={classes.submit}
                            disabled={loading}
                            onClick={() => { Acessar() }}
                        >
                            Acessar
                        </Button>

                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2" onClick={() => { EsqueciMinhaSenha() }}>
                                    Esqueceu a senha?
                                </Link>
                            </Grid>

                        </Grid>
                        <Box mt={5}>
                            <Copyright />
                        </Box>
                    </form>
                </div>
            </Grid>

            <Dialog
                open={loading}
                keepMounted
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title" style={{ fontSize: 16, textAlign: 'center' }} disableTypography={true}>{msgLoading}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">

                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress size={36} color={'primary'} />
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>

        </Grid>


        // <div className="pagina">
        //     <div className='box-login'>
        //         <div className='box-login-titulo'>
        //             <h1>Gestão de Validades</h1>
        //             <br />
        //             <div className='centraliza'>
        //                 < BiBarcodeReader
        //                     size={128}
        //                     color='#215497'
        //                 />
        //             </div>
        //         </div>
        //         <div className='box-login-inputs'>
        //             <Form>
        //                 <Form.Group className="mb-3" controlId="formBasicEmail">
        //                     <Form.Label>E-mail</Form.Label>
        //                     <Form.Control type="email" placeholder="E-mail" value={editUsuario} onChange={(e) => { setEditUsuario(e.target.value) }} />
        //                 </Form.Group>

        //                 <Form.Group className="mb-3" controlId="formBasicPassword">
        //                     <Form.Label>Senha</Form.Label>
        //                     <Form.Control type="password" placeholder="Senha" value={editSenha} onChange={(e) => { setEditSenha(e.target.value) }} />
        //                 </Form.Group>

        //                 <div className='box-botao-acessar'>
        //                     <Button variant="primary" className='botao-acessar' type="button" onClick={() => { Acessar() }}>
        //                         {!loading && 'Acessar'}
        //                         {loading &&
        //                             <Spinner
        //                                 as="span"
        //                                 animation="border"
        //                                 size="sm"
        //                                 role="status"
        //                                 aria-hidden="true"
        //                             />

        //                         }
        //                     </Button>
        //                 </div>

        //             </Form>
        //         </div>
        //     </div>
        // </div>



    )

}

export default Login;