import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const aviso = withReactContent(Swal)

export function msgAviso(titulo, texto, icone, botao) {
    aviso.fire({
        title: titulo,
        text: texto,
        icon: icone,
        confirmButtonColor: '#3085d6',
        confirmButtonText: botao,
    }).then((result) => {

    })
}

export function msgConfirmacao(titulo, texto, icone, botao) {
    aviso.fire({
        title: titulo,
        text: texto,
        icon: icone,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: botao,
        cancelButtonText: ''
    }).then((result) => {
        if (result.isConfirmed) {
            return true;
        }
    })
}
