import React, { useState, useContext, useEffect } from "react"
import {
    makeStyles,
    withStyles,
    Box,
    Toolbar,
    Grid,
    Typography,
    Dialog,
    DialogContentText,
    DialogTitle,
    CircularProgress,
    DialogContent,
    AccordionSummary,
    AccordionDetails,
    Accordion,
    ListItem,
    ListItemText,
    Card,
    CardContent,
    CardActions,
    Button,
    ListItemSecondaryAction,
    IconButton,
    Tooltip
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTheme } from '@material-ui/core/styles';
import { GlobalContext } from "../contexts/global";
import { msgAviso, msgConfirmacao } from "../components/sweet";
import { auth, db } from '../firebase'
import { TiDelete } from 'react-icons/ti'
import InfoIcon from '@material-ui/icons/Info';
import DataTable from 'react-data-table-component';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Sidebar from "../components/sidebar";
import BarraSuperior from "../components/barra";


const aviso = withReactContent(Swal)

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        backgroundColor: '#F4F6F8',
        flexGrow: 1,
        spacing: 4,
    },
    listItemText: {
        // fontSize: 18,
        padding: 0,
        textAlign: 'center',
        transition: '0.3s',
        '&:hover': {
            fontWeight: 'bold'
        }
    },
    listItemTextPrice: {
        fontSize: 18,
        // color: 'white',
        // backgroundColor: '#E58934',
        padding: 10,
        borderRadius: 5,
        textAlign: 'center',
        transition: '0.3s',
    },
    listSecondary: {
        // fontSize: 18,
        padding: 0,
        textAlign: 'center',
        transition: '0.3s',
        '&:hover': {
            fontWeight: 'bold'
        }
    },
    linha: {
        width: '100%',
    },
    listItem: {
        paddingTop: 4,
        paddingBottom: 4,
        height: 50,
        transition: '0.3s',
        '&:hover': {
            paddingLeft: 25,
        }
    },
    listItemPrice: {
        paddingTop: 4,
        paddingBottom: 4,
        height: 50,
        transition: '0.3s',
        '&:hover': {
            transform: 'scale(1.05)',
            backgroundColor: 'white'
        }
    },
    margin: {
        margin: theme.spacing(1),
    },
    heading: {
        fontSize: 18,
        fontWeight: 'bold'

    },
    card: {
        minWidth: '20%',
        height: 750,
        margin: 20,
        transition: '0.3s',
        '&:hover': {
            transform: 'scale(1.05)',
        }
    },
    cardPlano: {
        transition: '0.3s',
        '&:hover': {
            transform: 'scale(1.05)',
        }
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    subtitle: {
        fontWeight: 'lighter',
        textAlign: 'center',
    },
    pos: {
        marginTop: -5,
        textAlign: 'center',
        // backgroundColor: 'red'
    },
    number: {
        marginTop: 25,
        fontSize: 36,
        fontWeight: 'bold'
    },

    formulario: {
        display: 'flex',
        flexWrap: 'wrap',
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: '#E58934',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#E58934',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#E58934',
            },
            '&:hover fieldset': {
                borderColor: '#E58934',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#E58934',
            },
        },
    },
}));


function MeuPlano() {

    useEffect(() => {

        console.log('Chegou no useEffect do Relatórios')
        const conectado = auth.onAuthStateChanged(user => {
            if (user) {
             
                    salvarLogin(user.email)
                    ValidaUsuario(user.email)
                    console.log(user.email)
                    // CarregaColetas(user.email)
              
            } else {
                console.log('Sai Daqui')
                navigate("/?origem=meu-plano")
            }
        })

        return conectado;

    }, [])


    function ValidaUsuario(usuarioInformado) {

        setLoading(true)
        setMsgLoading('Obtendo Dados')

        console.log('Validando Licença do Usuário')

        const url = conexao.endereco + "/ValidaUsuario?usuario=" + usuarioInformado

        console.log('Endereço Configurado: ' + url)

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("GET", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("Authorization", conexao.autenticacao)
        requisicao.send()

        requisicao.ontimeout = function () {
            setLoading(false)
            console.log('Deu TimeOut')
        }

        requisicao.onerror = function () {
            setLoading(false)
            salvarDadosPlano('GRATUITO', '', '')
            console.log('Deu Erro: ' + this.responseText)
        }

        requisicao.onloadend = function () {
            setLoading(false)
            console.log('Deu Certo!')
            console.log('RESPONSE: ' + this.response)
            let resposta = JSON.parse(this.response)
            console.log('StatusCode: ' + requisicao.status)
            if (requisicao.status === 200) {

                salvarDadosPlano(resposta.plano, resposta.vencimento, resposta.status)

                // if (resposta.plano == 'GRATUITO') {
                //     navigate("/meu-plano")
                // }

                registrarQtdeTimes(resposta.times)

            } else {
                salvarDadosPlano('GRATUITO', '', '')
            }
            console.log(resposta);
        }

    }

    function EscolherPlano(valor, link, condicao, plano) {

        aviso.fire({
            title: 'Confirma Assinatura?',
            text: 'Você Escolheu o Plano ' + plano + ' - ' + condicao + ' no Valor de ' + valor,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#E58934',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                window.open(link, '_blank');
            }
        })
    }

    function PlanoGratuito() {

        aviso.fire({
            title: 'Confirma Escolha?',
            text: 'Você Escolheu o Plano Gratuito',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#E58934',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                // alert('Mão de Vaca!')
            }
        })
    }

    function PlanoCompleto() {

        aviso.fire({
            title: 'Parabéns pela Escolha!',
            text: 'Você Escolheu o Plano Completo, Ao Confirmar Um Consultor Entrará em Contato!',
            icon: 'success',
            showCancelButton: true,
            confirmButtonColor: '#E58934',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                alert('Gerar Pendência Comercial')
            }
        })
    }

    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 300,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }))(Tooltip);



    const colunasPlanos = [
        {
            name: 'Plano',
            selector: row => row.nome,
            sortable: true,
            style: { fontWeight: 'bold' }
        },
        {
            name: 'Qtde Usuários',
            selector: row => row.usuarios,
            sortable: true,
        },
        {
            name: 'Qtde Times',
            selector: row => row.times,
            sortable: true,
        },
        {
            name: 'Dashboard',
            selector: row => row.dashboard,
            sortable: true,
        },
        {
            name: 'Plano de Ação',
            selector: row => row.planoacao,
            sortable: true,
        },
        {
            name: 'Notificações via WhatsApp',
            selector: row => row.whatsapp,
            sortable: true,
        },
        {
            name: 'Integração com ERP',
            selector: row => row.integracao,
            sortable: true,
        },
        {
            name: 'Pagamento Mensal',
            selector: row => row.pgtoMes,
            sortable: true,
        },
        {
            name: 'Pagamento Semestral',
            selector: row => row.pgtoSemestre,
            sortable: true,
        },
        {
            name: 'Pagamento Anual',
            selector: row => row.pgtoAnual,
            sortable: true,
        },
    ]

    const planos = [
        {
            "identificacao": "free",
            "nome": "GRATUITO",
            "usuarios": "1",
            "times": "0",
            "dashboard": "Básico",
            "planoacao": "Não",
            "whatsapp": "Não",
            "integracao": "Não",
            "pgtoMes": "FREE",
            "pgtoSemestre": "FREE",
            "pgtoAnual": "FREE",

        },
        {
            "identificacao": "basico",
            "nome": "BÁSICO",
            "usuarios": "3",
            "times": "2",
            "dashboard": "Limitado",
            "planoacao": "Não",
            "whatsapp": "Não",
            "integracao": "Não",
            "pgtoMes": "R$ 49,90",
            "linkMes": "http://pag.ae/7YoUHaRCH",
            "pgtoSemestre": "R$ 239,40",
            "linkSemestre": "http://pag.ae/7YoUHr6Vs",
            "pgtoAnual": "R$ 358,80",
            "linkAnual": "http://pag.ae/7YoUHEteq"

        },
        {
            "identificacao": "pro",
            "nome": "PRÓ",
            "usuarios": "20",
            "times": "5",
            "dashboard": "Limitado",
            "planoacao": "Sim",
            "whatsapp": "Não",
            "integracao": "Não",
            "pgtoMes": "R$ 69,90",
            "linkMes": "http://pag.ae/7YoUJHUg2",
            "pgtoSemestre": "R$ 359,40",
            "linkSemestre": "http://pag.ae/7YoUK7TYL",
            "pgtoAnual": "R$ 598,80",
            "linkAnual": "http://pag.ae/7YoUKAa67"

        },
        {
            "identificacao": "completo",
            "nome": "COMPLETO",
            "usuarios": "Ilimitados",
            "times": "Ilimitados",
            "dashboard": "Completo",
            "planoacao": "Sim",
            "whatsapp": "Sim",
            "integracao": "Sim",
            "pgtoMes": "CONSULTAR",
            "pgtoSemestre": "CONSULTAR",
            "pgtoAnual": "CONSULTAR",

        },


    ];

    const Navigate = useNavigate()

    const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [msgLoading, setMsgLoading] = useState('')

    const { conexao, usuario, qtdeTimes, salvarDadosPlano, registrarQtdeTimes, registrarCodigoTime, registrarTimeAtual, timeAtual, codigoTime, salvarLogin, plano } = useContext(GlobalContext)

    return (
        <div className={classes.root}>
            <BarraSuperior />
            <Box display='flex'>
                <Sidebar />
                <div className={classes.root}>
                    <Box p={8} xs={1} style={{ backgroundColor: '#F4F6F8', paddingBottom: 200 }}>
                        <Toolbar />
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Typography
                                    component={'span'}
                                    variant='h5'
                                    color='textPrimary'
                                    style={{ fontWeight: 600 }}
                                >
                                    Meu Plano
                                </Typography>

                                {plano.plano &&
                                    <Typography
                                        variant='h6'
                                        color='textPrimary'
                                        style={{ fontWeight: 400 }}
                                    >
                                        Plano Atual: {plano.plano}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>

                        <br />

                        {plano.plano &&

                            <Grid container spacing={3} xs={12}>
                                <Grid item xs={12} md={3} lg={3} >

                                    <Accordion expanded={true} className={classes.cardPlano}>
                                        <AccordionSummary
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className={classes.heading}>Plano Gratuito</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>

                                            <div className={classes.linha}>

                                                <ListItem button className={classes.listItem} key={plano.identificacao}>
                                                    <ListItemText
                                                        classes={{
                                                            primary: classes.listItemText,
                                                        }}
                                                        primary={planos.find(plano => plano.identificacao === 'free')?.usuarios + ' Usuário'}
                                                    />
                                                </ListItem>
                                                <ListItem button className={classes.listItem} key={plano.identificacao}>
                                                    <ListItemText
                                                        classes={{
                                                            primary: classes.listItemText,
                                                        }}
                                                        primary={planos.find(plano => plano.identificacao === 'free')?.times + ' Times'}
                                                    />
                                                </ListItem>
                                                <ListItem button className={classes.listItem} key={plano.identificacao}>
                                                    <ListItemText
                                                        classes={{
                                                            primary: classes.listItemText,
                                                        }}
                                                        primary={'Dashboard ' + planos.find(plano => plano.identificacao === 'free')?.dashboard}
                                                    />
                                                </ListItem>
                                                <hr />
                                                <ListItem button className={classes.listItem} key={plano.identificacao} onClick={() => {
                                                    PlanoGratuito()
                                                }}>
                                                    <ListItemText
                                                        classes={{
                                                            primary: classes.listItemText,
                                                        }}
                                                        primary={"100% Gratuito"}
                                                    />
                                                </ListItem>



                                            </div>



                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid item xs={12} md={3} lg={3}>

                                    <Accordion expanded={true} className={classes.cardPlano}>
                                        <AccordionSummary
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className={classes.heading}>Plano Básico</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>

                                            <div className={classes.linha}>

                                                <ListItem button className={classes.listItem} key={plano.identificacao}>
                                                    <ListItemText
                                                        classes={{
                                                            primary: classes.listItemText,
                                                        }}
                                                        primary={planos.find(plano => plano.identificacao === 'basico')?.usuarios + " Usuários"}
                                                    />
                                                </ListItem>

                                                <ListItem button className={classes.listItem} key={plano.identificacao}>
                                                    <ListItemText
                                                        classes={{
                                                            primary: classes.listItemText,
                                                        }}
                                                        primary={planos.find(plano => plano.identificacao === 'basico')?.times + " Times"}
                                                    />
                                                </ListItem>

                                                <ListItem button className={classes.listItem} key={plano.identificacao}>
                                                    <ListItemText
                                                        classes={{
                                                            primary: classes.listItemText,
                                                        }}
                                                        primary={"Dashboard " + planos.find(plano => plano.identificacao === 'basico')?.dashboard}
                                                    />
                                                </ListItem>

                                                <hr />
                                                <ListItem button className={classes.listItem} key={plano.identificacao} onClick={() => {
                                                    EscolherPlano(
                                                        planos.find(plano => plano.identificacao === 'basico')?.pgtoMes,
                                                        planos.find(plano => plano.identificacao === 'basico')?.linkMes,
                                                        "Pagamento Mensal",
                                                        "Básico"
                                                    )
                                                }}>
                                                    <ListItemText
                                                        classes={{
                                                            primary: classes.listItemText,
                                                            secondary: classes.listSecondary,
                                                        }}
                                                        primary={planos.find(plano => plano.identificacao === 'basico')?.pgtoMes}
                                                        secondary={"Pagamento Mensal"}
                                                    />
                                                </ListItem>
                                                <hr />
                                                <ListItem button className={classes.listItem} key={plano.identificacao} onClick={() => {
                                                    EscolherPlano(
                                                        planos.find(plano => plano.identificacao === 'basico')?.pgtoSemestre,
                                                        planos.find(plano => plano.identificacao === 'basico')?.linkSemestre,
                                                        "Pagamento Semestral",
                                                        "Básico"
                                                    )
                                                }}>
                                                    <HtmlTooltip title={
                                                        <React.Fragment>
                                                            <Typography color="inherit">Valor Mensal Nesta Condição</Typography>
                                                            <br />
                                                            <h4 textAlign="center">R$ 39,90</h4>
                                                        </React.Fragment>
                                                    }>
                                                        <ListItemText
                                                            classes={{
                                                                primary: classes.listItemText,
                                                                secondary: classes.listSecondary,
                                                            }}
                                                            primary={planos.find(plano => plano.identificacao === 'basico')?.pgtoSemestre}
                                                            secondary={"Pagamento Semestral"}
                                                        />
                                                    </HtmlTooltip>
                                                </ListItem>

                                                <hr />
                                                <ListItem button className={classes.listItem} key={plano.identificacao} onClick={() => {
                                                    EscolherPlano(
                                                        planos.find(plano => plano.identificacao === 'basico')?.pgtoAnual,
                                                        planos.find(plano => plano.identificacao === 'basico')?.linkAnual,
                                                        "Pagamento Anual",
                                                        "Básico"
                                                    )

                                                }}>
                                                    <HtmlTooltip title={
                                                        <React.Fragment>
                                                            <Typography color="inherit">Valor Mensal Nesta Condição</Typography>
                                                            <br />
                                                            <h4 textAlign="center">R$ 29,90</h4>
                                                        </React.Fragment>
                                                    }>
                                                        <ListItemText
                                                            classes={{
                                                                primary: classes.listItemText,
                                                                secondary: classes.listSecondary,
                                                            }}
                                                            primary={planos.find(plano => plano.identificacao === 'basico')?.pgtoAnual}
                                                            secondary={"Pagamento Anual"}
                                                        />
                                                    </HtmlTooltip>
                                                </ListItem>




                                            </div>



                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid item xs={12} md={3} lg={3} >

                                    <Accordion expanded={true} className={classes.cardPlano}>
                                        <AccordionSummary
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className={classes.heading}>Plano Pró</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>

                                            <div className={classes.linha}>

                                                <ListItem button className={classes.listItem} key={plano.identificacao}>
                                                    <ListItemText
                                                        classes={{
                                                            primary: classes.listItemText,
                                                        }}
                                                        primary={planos.find(plano => plano.identificacao === 'pro')?.usuarios + " Usuários"}
                                                    />
                                                </ListItem>

                                                <ListItem button className={classes.listItem} key={plano.identificacao}>
                                                    <ListItemText
                                                        classes={{
                                                            primary: classes.listItemText,
                                                        }}
                                                        primary={planos.find(plano => plano.identificacao === 'pro')?.times + " Times"}
                                                    />
                                                </ListItem>

                                                <ListItem button className={classes.listItem} key={plano.identificacao}>
                                                    <ListItemText
                                                        classes={{
                                                            primary: classes.listItemText,
                                                        }}
                                                        primary={"Dashboard " + planos.find(plano => plano.identificacao === 'pro')?.dashboard}
                                                    />
                                                </ListItem>

                                                <ListItem button className={classes.listItem} key={plano.identificacao}>
                                                    <ListItemText
                                                        classes={{
                                                            primary: classes.listItemText,
                                                        }}
                                                        primary={"Planos de Ação"}
                                                    />
                                                </ListItem>

                                                <hr />
                                                <ListItem button className={classes.listItem} key={plano.identificacao} onClick={() => {
                                                    EscolherPlano(
                                                        planos.find(plano => plano.identificacao === 'pro')?.pgtoMes,
                                                        planos.find(plano => plano.identificacao === 'pro')?.linkMes,
                                                        "Pagamento Mensal",
                                                        "Pró"
                                                    )

                                                }}>
                                                    <ListItemText
                                                        classes={{
                                                            primary: classes.listItemText,
                                                            secondary: classes.listSecondary,
                                                        }}
                                                        primary={planos.find(plano => plano.identificacao === 'pro')?.pgtoMes}
                                                        secondary={"Pagamento Mensal"}
                                                    />
                                                </ListItem>
                                                <hr />
                                                <ListItem button className={classes.listItem} key={plano.identificacao} onClick={() => {
                                                    EscolherPlano(
                                                        planos.find(plano => plano.identificacao === 'pro')?.pgtoSemestre,
                                                        planos.find(plano => plano.identificacao === 'pro')?.linkSemestre,
                                                        "Pagamento Semestral",
                                                        "Pró"
                                                    )

                                                }}>
                                                    <HtmlTooltip title={
                                                        <React.Fragment>
                                                            <Typography color="inherit">Valor Mensal Nesta Condição</Typography>
                                                            <br />
                                                            <h4 textAlign="center">R$ 59,90</h4>
                                                        </React.Fragment>
                                                    }>
                                                        <ListItemText
                                                            classes={{
                                                                primary: classes.listItemText,
                                                                secondary: classes.listSecondary,
                                                            }}
                                                            primary={planos.find(plano => plano.identificacao === 'pro')?.pgtoSemestre}
                                                            secondary={"Pagamento Semestral"}
                                                        />
                                                    </HtmlTooltip>
                                                </ListItem>
                                                <hr />
                                                <ListItem button className={classes.listItem} key={plano.identificacao} onClick={() => {
                                                    EscolherPlano(
                                                        planos.find(plano => plano.identificacao === 'pro')?.pgtoAnual,
                                                        planos.find(plano => plano.identificacao === 'pro')?.linkAnual,
                                                        "Pagamento Anual",
                                                        "Pró"
                                                    )

                                                }}>
                                                    <HtmlTooltip title={
                                                        <React.Fragment>
                                                            <Typography color="inherit">Valor Mensal Nesta Condição</Typography>
                                                            <br />
                                                            <h4 textAlign="center">R$ 49,90</h4>
                                                        </React.Fragment>
                                                    }>
                                                        <ListItemText
                                                            classes={{
                                                                primary: classes.listItemText,
                                                                secondary: classes.listSecondary,
                                                            }}
                                                            primary={planos.find(plano => plano.identificacao === 'pro')?.pgtoAnual}
                                                            secondary={"Pagamento Anual"}
                                                        />
                                                    </HtmlTooltip>
                                                </ListItem>

                                            </div>



                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid item xs={12} md={3} lg={3}>

                                    <Accordion expanded={true} className={classes.cardPlano}>
                                        <AccordionSummary
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className={classes.heading}>Plano Completo</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>

                                            <div className={classes.linha}>

                                                <ListItem button className={classes.listItem} key={plano.identificacao}>
                                                    <ListItemText
                                                        classes={{
                                                            primary: classes.listItemText,
                                                        }}
                                                        primary={"Usuários " + planos.find(plano => plano.identificacao === 'completo')?.usuarios}
                                                    />
                                                </ListItem>

                                                <ListItem button className={classes.listItem} key={plano.identificacao}>
                                                    <ListItemText
                                                        classes={{
                                                            primary: classes.listItemText,
                                                        }}
                                                        primary={"Times " + planos.find(plano => plano.identificacao === 'completo')?.times}
                                                    />
                                                </ListItem>

                                                <ListItem button className={classes.listItem} key={plano.identificacao}>
                                                    <ListItemText
                                                        classes={{
                                                            primary: classes.listItemText,
                                                        }}
                                                        primary={"Dashboard " + planos.find(plano => plano.identificacao === 'completo')?.dashboard}
                                                    />
                                                </ListItem>

                                                <ListItem button className={classes.listItem} key={plano.identificacao}>
                                                    <ListItemText
                                                        classes={{
                                                            primary: classes.listItemText,
                                                        }}
                                                        primary={"Planos de Ação"}
                                                    />
                                                </ListItem>

                                                <ListItem button className={classes.listItem} key={plano.identificacao}>
                                                    <ListItemText
                                                        classes={{
                                                            primary: classes.listItemText,
                                                        }}
                                                        primary={"Integração com WhatsApp"}
                                                    />
                                                </ListItem>

                                                <ListItem button className={classes.listItem} key={plano.identificacao}>
                                                    <ListItemText
                                                        classes={{
                                                            primary: classes.listItemText,
                                                        }}
                                                        primary={"Integração com ERP"}
                                                    />
                                                </ListItem>

                                                <hr />
                                                <ListItem button className={classes.listItem} key={plano.identificacao} onClick={() => {
                                                    PlanoCompleto()
                                                }}>
                                                    <ListItemText
                                                        classes={{
                                                            primary: classes.listItemText,
                                                            secondary: classes.listSecondary,
                                                        }}
                                                        primary={"Sob Consulta"}
                                                    // secondary={"Pagamento Mensal"}
                                                    />
                                                </ListItem>

                                            </div>



                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>

                                {/* <DataTable
                                    columns={colunasPlanos}
                                    data={planos}
                                    // onRowClicked={(row, index) => { CarregaDetalheColetas(row.departamento, row.vencimento) }}
                                    striped={true}
                                    highlightOnHover={true}
                                    responsive={true}
                                    pointerOnHover={true}
                                    noDataComponent={"Nenhum Registro Encontrado =/"}

                                /> */}

                                {/* <Card className={classes.card}>
                                    <CardContent>
                                        <hr />
                                        <Typography className={classes.title} color="title" gutterBottom>
                                            GRATUITO
                                        </Typography>
                                        <hr />

                                        <Typography className={classes.number} variant="h5" component="h2">
                                            FREE
                                        </Typography>

                                        <hr />
                                        <Typography className={classes.title} color="title" gutterBottom>
                                            RECURSOS
                                        </Typography>
                                        <hr />
                                        <ListItem button className={classes.listItem} key={1}>
                                            <ListItemText
                                                classes={{
                                                    primary: classes.listItemText,
                                                }}
                                                primary={'1 Usuário'}
                                            />
                                        </ListItem>
                                        <ListItem button className={classes.listItem} key={1}>
                                            <ListItemText
                                                classes={{
                                                    primary: classes.listItemText,
                                                }}
                                                primary={'Sem Times'}
                                            />
                                        </ListItem>
                                        <ListItem button className={classes.listItem} key={1}>
                                            <ListItemText
                                                classes={{
                                                    primary: classes.listItemText,
                                                }}
                                                primary={'Dashboard Básico'}
                                            />
                                        </ListItem>

                                    </CardContent>


                                    <CardActions>
                                        <Button
                                            size="large"
                                            fullWidth
                                            style={{ backgroundColor: '#E58934', color: 'white' }}
                                        >
                                            Assinar
                                        </Button>
                                    </CardActions>


                                </Card>

                                <Card className={classes.card}>
                                    <CardContent>
                                        <hr />
                                        <Typography className={classes.title} color="title" gutterBottom>
                                            BÁSICO
                                        </Typography>
                                        <hr />
                                        <Typography className={classes.number} variant="h5" component="h2">
                                            49,90
                                        </Typography>
                                        <Typography className={classes.pos} color="subtitle">
                                            Pagamento Mensal
                                        </Typography>
                                        <Typography className={classes.number} variant="h5" component="h2">
                                            39,90
                                        </Typography>
                                        <Typography className={classes.pos} color="subtitle">
                                            Pagamento Semestral
                                        </Typography>
                                        <Typography className={classes.number} variant="h5" component="h2">
                                            29,90
                                        </Typography>
                                        <Typography className={classes.pos} color="subtitle">
                                            Pagamento Anual
                                        </Typography>
                                        <hr />
                                        <Typography className={classes.title} color="title" gutterBottom>
                                            RECURSOS
                                        </Typography>
                                        <hr />
                                        <ListItem button className={classes.listItem} key={1}>
                                            <ListItemText
                                                classes={{
                                                    primary: classes.listItemText,
                                                }}
                                                primary={'3 Usuários'}
                                            />
                                        </ListItem>
                                        <ListItem button className={classes.listItem} key={1}>
                                            <ListItemText
                                                classes={{
                                                    primary: classes.listItemText,
                                                }}
                                                primary={'2 Times'}
                                            />
                                        </ListItem>
                                        <ListItem button className={classes.listItem} key={1}>
                                            <ListItemText
                                                classes={{
                                                    primary: classes.listItemText,
                                                }}
                                                primary={'Dashboard Limitado'}
                                            />
                                        </ListItem>

                                    </CardContent>

                                    <CardActions>
                                        <Button
                                            size="large"
                                            fullWidth
                                            style={{ backgroundColor: '#E58934', color: 'white' }}
                                        >
                                            Assinar
                                        </Button>
                                    </CardActions>

                                </Card>

                                <Card className={classes.card}>
                                    <CardContent>
                                        <hr />
                                        <Typography className={classes.title} color="title" gutterBottom>
                                            PRÓ
                                        </Typography>
                                        <hr />
                                        <Typography className={classes.number} variant="h5" component="h2">
                                            69,90
                                        </Typography>
                                        <Typography className={classes.pos} color="subtitle">
                                            Pagamento Mensal
                                        </Typography>
                                        <Typography className={classes.number} variant="h5" component="h2">
                                            59,90
                                        </Typography>
                                        <Typography className={classes.pos} color="subtitle">
                                            Pagamento Semestral
                                        </Typography>
                                        <Typography className={classes.number} variant="h5" component="h2">
                                            49,90
                                        </Typography>
                                        <Typography className={classes.pos} color="subtitle">
                                            Pagamento Anual
                                        </Typography>
                                        <hr />
                                        <Typography className={classes.title} color="title" gutterBottom>
                                            RECURSOS
                                        </Typography>
                                        <hr />
                                        <ListItem button className={classes.listItem} key={1}>
                                            <ListItemText
                                                classes={{
                                                    primary: classes.listItemText,
                                                }}
                                                primary={'Usuários Ilimitados'}
                                            />
                                        </ListItem>
                                        <ListItem button className={classes.listItem} key={1}>
                                            <ListItemText
                                                classes={{
                                                    primary: classes.listItemText,
                                                }}
                                                primary={'Times Ilimitados'}
                                            />
                                        </ListItem>
                                        <ListItem button className={classes.listItem} key={1}>
                                            <ListItemText
                                                classes={{
                                                    primary: classes.listItemText,
                                                }}
                                                primary={'Dashboard Limitado'}
                                            />
                                        </ListItem>
                                        <ListItem button className={classes.listItem} key={1}>
                                            <ListItemText
                                                classes={{
                                                    primary: classes.listItemText,
                                                }}
                                                primary={'Planos de Ação'}
                                            />
                                        </ListItem>

                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            size="large"
                                            fullWidth
                                            style={{ backgroundColor: '#E58934', color: 'white' }}
                                        >
                                            Assinar
                                        </Button>
                                    </CardActions>

                                </Card>

                                <Card className={classes.card}>
                                    <CardContent>
                                        <hr />
                                        <Typography className={classes.title} color="title" gutterBottom>
                                            COMPLETO
                                        </Typography>
                                        <hr />

                                        <Typography className={classes.number} variant="h5" component="h2">
                                            SOB CONSULTA
                                        </Typography>

                                        <hr />
                                        <Typography className={classes.title} color="title" gutterBottom>
                                            RECURSOS
                                        </Typography>
                                        <hr />
                                        <ListItem button className={classes.listItem} key={1}>
                                            <ListItemText
                                                classes={{
                                                    primary: classes.listItemText,
                                                }}
                                                primary={'Usuários Ilimitados'}
                                            />
                                        </ListItem>
                                        <ListItem button className={classes.listItem} key={1}>
                                            <ListItemText
                                                classes={{
                                                    primary: classes.listItemText,
                                                }}
                                                primary={'Times Ilimitados'}
                                            />
                                        </ListItem>
                                        <ListItem button className={classes.listItem} key={1}>
                                            <ListItemText
                                                classes={{
                                                    primary: classes.listItemText,
                                                }}
                                                primary={'Dashboard Completo'}
                                            />
                                        </ListItem>
                                        <ListItem button className={classes.listItem} key={1}>
                                            <ListItemText
                                                classes={{
                                                    primary: classes.listItemText,
                                                }}
                                                primary={'Planos de Ação'}
                                            />
                                        </ListItem>
                                        <ListItem button className={classes.listItem} key={1}>
                                            <ListItemText
                                                classes={{
                                                    primary: classes.listItemText,
                                                }}
                                                primary={'Notificações via WhatsApp'}
                                            />
                                        </ListItem>
                                        <ListItem button className={classes.listItem} key={1}>
                                            <ListItemText
                                                classes={{
                                                    primary: classes.listItemText,
                                                }}
                                                primary={'Integração com ERP'}
                                            />
                                        </ListItem>

                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            size="large"
                                            fullWidth
                                            style={{ backgroundColor: '#E58934', color: 'white' }}
                                        >
                                            Assinar
                                        </Button>
                                    </CardActions>

                                </Card> */}


                            </Grid>
                        }

                    </Box>




                </div>


                {/* <Dialog
                    open={loading}
                    keepMounted
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title" style={{ fontSize: 16, textAlign: 'center' }} disableTypography={true}>{msgLoading}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">

                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <CircularProgress size={24} color={'primary'} />
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog> */}






            </Box >

        </div >
    )
}

export default MeuPlano